import { Component, DestroyRef, inject, Input, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NonNullableFormBuilder } from "@angular/forms";
import { RoamToastrService } from "@app/pages/task/+data-access";
import { VendorService } from "@app/shared/services/vendor.service";

@Component({
  selector: "app-global-form-contact-vendor",
  templateUrl: "./global-form-contact-vendor.component.html",
})
export class GlobalFormContactVendorComponent {
  #destroyRef = inject(DestroyRef);
  #api = inject(VendorService);
  protected readonly toastr = inject(RoamToastrService);
  protected readonly fb = inject(NonNullableFormBuilder);
  readonly isSubmitting = signal(false);

  @Input()
  public contactDetail?: any;

  @Input()
  public menu!: string;

  @Input()
  public tab?: string;

  readonly form = this.fb.group({
    vendor: this.fb.group({
      name: this.fb.control<string>(""),
      email: this.fb.control<string>(""),
    }),
  });

  get controls() {
    return this.form.controls;
  }

  onVendorSubmit(): void {
    if (this.contactDetail?.id) {
      this.patchVendor(this.contactDetail.id);
    } else {
      this.addVendor();
    }
  }

  addVendor(): void {
    const v = this.controls.vendor.getRawValue();
    this.isSubmitting.set(true);
    this.#api
      .addVendor({
        id: crypto.randomUUID(),
        ...v,
      })
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.toastr.success("Vendor created successfully!");
          this.isSubmitting.set(false);
        },
        error: () => {
          this.toastr.danger("Failed to create vendor!");
          this.isSubmitting.set(false);
        },
      });
  }

  patchVendor(id: string): void {
    const v = this.controls.vendor.getRawValue();
    this.isSubmitting.set(true);
    this.#api
      .patchVendor(id, v)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.toastr.success("Vendor updated successfully!");
          this.isSubmitting.set(false);
        },
        error: () => {
          this.toastr.danger("Failed to update vendor!");
          this.isSubmitting.set(false);
        },
      });
  }

  ngOnInit(): void {
    const prop = this.contactDetail;
    if (prop) {
      this.controls.vendor.patchValue({
        name: prop.name,
        email: prop.contactEmail,
      });
    }
  }
}
