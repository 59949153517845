<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <label class="boldText">Set your parking policy for this property.</label>

    <div class="set-grid set-grid-4 pt-10">
      @for(item of ParkingCategory;track item.id){
      <app-shortcut-menu-card
        [shortcut]="item"
        (click)="selectParkingCategory(item)"
      >
      </app-shortcut-menu-card>
      }
    </div>

    @if(ParkingCategory[0].focus){
    <div class="parking-detail">
      <label class="fw-500">Parking Details</label>
      <label class="fw-300">Set your parking policy for this property.</label>
      <div class="parking-option">
        <mat-checkbox class="example-margin">Inside</mat-checkbox>
        <mat-checkbox class="example-margin">Outside</mat-checkbox>
        <mat-checkbox class="example-margin">Garage</mat-checkbox>
      </div>
      <app-roam-text-area
        [name]="
          'If you have any specific parking restrictions, please add them here..'
        "
      ></app-roam-text-area>
    </div>
    }
  </ng-container>
</app-container-dialog-set>
