import { Component, input, output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { iconLib } from "../../../../../../core/const/roam-icon";

type FormType = FormGroup<{
  name: FormControl<string>;
  email: FormControl<string>;
}>;

@Component({
  selector: "app-global-form-contact-form-vendor",
  templateUrl: "./global-form-contact-form-vendor.component.html",
})
export class GlobalFormContactTenantVendorComponent {
  icon = iconLib;

  readonly form = input.required<FormType>();
  readonly formSubmit = output<void>();

  get fc() {
    return this.form().controls;
  }

  close(): void {
    //TODO: Check form valid before close the dialog
    this.dialogRef.close();
  }

  constructor(
    private dialogRef: MatDialogRef<GlobalFormContactTenantVendorComponent>
  ) {}
}
