import { Component, signal } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { iconLib } from "@app/core/const/roam-icon";
import { ToastrService } from "ngx-toastr";
import { TOASTR_MESSAGE_ARCHITECTURAL } from "../../../const";

@Component({
  selector: "app-global-form-association-compliance-architectural-requests",
  templateUrl:
    "./global-form-association-compliance-architectural-requests.component.html",
  styleUrls: [
    "./global-form-association-compliance-architectural-requests.component.scss",
  ],
})
export class GlobalFormAssociationComplianceArchitecturalRequestsComponent {
  public isLoading = signal<boolean>(false);
  public form: FormGroup;
  public iconButton = iconLib;
  public toastrText = TOASTR_MESSAGE_ARCHITECTURAL;
  public files: any[] = [
    {
      name: "File Name.pdf",
      type: "pdf",
      date: "April 30, 2023",
      user: "Rowdy",
      size: 1800,
      file: {},
    },
    {
      name: "File Name 2.pdf",
      type: "pdf",
      date: "April 30, 2023",
      user: "Rowdy",
      size: 12000,
      file: {},
    },
  ];

  uploadFile(file: File): void {
    const { name, size, lastModified } = file;

    const newFile = {
      name: name,
      size: size,
      date: new Date(lastModified),
      user: "User",
      file: file,
    };
    this.files.push(newFile);
    this.form.controls["files"].setValue(this.files);
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.form.controls["files"].setValue(this.files);
  }

  submit(): void {}

  close(): void {}

  constructor(
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      files: new FormArray([]),
    });
  }
}
