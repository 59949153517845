import { Component, signal } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: "app-global-form-association-compliance-inspections",
  templateUrl:
    "./global-form-association-compliance-inspections.component.html",
  styleUrls: [
    "./global-form-association-compliance-inspections.component.scss",
  ],
})
export class GlobalFormAssociationComplianceInspectionsComponent {
  public form: FormGroup;
  public icon = iconLib;
  public isLoading = signal<boolean>(false);

  get formInspections(): FormArray {
    return this.form.get("inspectionsCategory") as FormArray;
  }

  createFormInspections(): FormGroup {
    return this.formBuilder.group({
      categoryName: [""],
    });
  }

  addFormInspections(): any {
    this.formInspections.push(this.createFormInspections());
  }

  removeCategory(index: number): any {
    this.formInspections.removeAt(index);
  }

  submit(): any {}
  close(): any {}

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      inspectionsCategory: this.formBuilder.array([
        this.createFormInspections(),
      ]),
    });
  }
}
