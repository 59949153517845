<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Emergency Contact</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <div>
      <div class="mb-24" *ngFor="let contact of [1, 2]; let contact_i = index">
        <ng-container>
          <app-roam-form-info
            [title]="'Emergency Contact ' + (contact_i + 1)"
            [isDelete]="contact_i !== 0"
            [bg]="'dark'"
          >
            <ng-container content>
              <div class="form-section f-col-2">
                <app-roam-input [name]="'Name'"></app-roam-input>
                <app-roam-input [name]="'Relationship'"></app-roam-input>
                <app-roam-input [name]="'Email'"></app-roam-input>
                <app-roam-input [name]="'Phone'"></app-roam-input>
              </div>
            </ng-container>
          </app-roam-form-info>
        </ng-container>
      </div>

      <div class="mt-24">
        <app-button-add
          [label]="'Add Another'"
          [round]="true"
          (onAdd)="addContactForm()"
        ></app-button-add>
      </div>
    </div>
  </ng-container>
</app-container-dialog-set>
