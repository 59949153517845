<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Customer Account Info</h1>
  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="formSubmit.emit()">
  <div container class="form-wrapper">
    <form [formGroup]="form()">
      <div class="form-row">
        <div class="form-field">
          <app-roam-input formControlName="name" name="Account Name" required />
        </div>
      </div>
      <div class="form-row">
        <div class="form-field">
          <app-roam-input
            formControlName="accountNumber"
            name="Account Number" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-field">
          <app-roam-input formControlName="generation" name="Generation" />
        </div>
      </div>
    </form>
  </div>
</app-container-dialog-set>
