<div>
  <app-global-form-tabs
    (tabSelectedChange)="checkForFormDirty()"
    [tabs]="tabs"
    [(tabSelected)]="tab" />

  <hr class="mb-2rem" />

  <ng-container [ngSwitch]="tab">
    <app-global-form-unit-general-information
      *ngSwitchCase="'information'"
      [unitDetail]="unitDetail" />

    <app-container-dialog-set *ngSwitchCase="'addresses'">
      <form container [formGroup]="form">
        <div class="set-grid set-grid-2">
          <div class="pt-10">
            <app-roam-input formControlName="street1" name="Street 1" />
          </div>
          <div class="pt-10">
            <app-roam-input formControlName="street2" name="Street 2" />
          </div>
          <div class="pt-10">
            <app-roam-input formControlName="city" name="City" />
          </div>
          <div class="pt-10">
            <app-roam-input formControlName="state" name="State" />
          </div>
          <div class="pt-10">
            <app-roam-input formControlName="country" name="Country" />
          </div>
          <div class="pt-10">
            <app-roam-input formControlName="zipCode" name="Zip Code" />
          </div>
        </div>
      </form>
    </app-container-dialog-set>
  </ng-container>
</div>
