<div class="p-relative">
  @if(labelName != ""){
  <span class="close-btn" (click)="clearLabel()">
    <span class="svg-16 d-flex pl-5" [inlineSVG]="icon.closeBlack.src"></span>
  </span>
  }

  <input-dropdown
    cdkOverlayOrigin
    #openMenu="cdkOverlayOrigin"
    placeholder="Select architectural request instructions"
    [floatingLabel]="false"
    [(ngModel)]="labelName"
    (click)="isDropdown = !isDropdown"
  />
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="openMenu"
  [cdkConnectedOverlayOpen]="isDropdown"
  cdkConnectedOverlayPush="true"
  [cdkConnectedOverlayWidth]="320"
  [cdkConnectedOverlayPositions]="dropdownPosition"
  [cdkConnectedOverlayViewportMargin]="7"
  (backdropClick)="isDropdown = false"
>
  <div class="dropdown pl-16 pr-16 pt-8 pb-0">
    <div class="pt-8 pb-16">
      <app-input-search-one (onSearch)="searchArchitectural($event)" />
    </div>

    <div class="create-new-button" (click)="openCreateMenu()">
      <button mat-icon-button class="chip-button">
        <mat-icon fontSet="material-icons-outlined">add</mat-icon>
      </button>
      Create New
    </div>

    <hr />

    <div class="gutter scroll-container">
      <div class="reverse hovered">
        @for (data of SAMPLE_Architectural; track data) {
        <div class="list-data-button" (click)="selectData(data)">
          {{ data.name }}
        </div>
        }
      </div>
    </div>
  </div>
</ng-template>
