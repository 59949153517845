import { Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputDropdownComponent } from "@app/shared/components/input/input-dropdown/input-dropdown.component";
import { ConnectionPositionPair, OverlayModule } from "@angular/cdk/overlay";
import { dropdownPosition } from "@app/core/const/overlay.const";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { InputSearchOneComponent } from "@app/shared/components/input/input-search-one/input-search-one.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from "@angular/material/icon";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { InputModule } from "@app/shared/components/input/input.module";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputDropdownComponent,
    OverlayModule,
    FormsModule,
    InputSearchOneComponent,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatIconModule,
    RoamButtonComponent,
    InputModule,
  ],
  selector: "contact-select-vendor-dropdown",
  templateUrl: "./select-vendor-dropdown.component.html",
  styleUrls: ["./select-vendor-dropdown.component.scss"],
})
export class SelectVendorDropdownComponent {
  #fb = inject(FormBuilder).nonNullable;

  isDropdown!: boolean;
  createNewVendor: boolean = false;
  labelName!: string;
  dropdownPosition: ConnectionPositionPair[] = dropdownPosition;

  readonly form = this.#fb.group({
    vendorName: this.#fb.control("", [Validators.required]),
    city: this.#fb.control("", [Validators.required]),
    state: this.#fb.control("", [Validators.required]),
  });

  SAMPLE_VENDORS = [
    {
      id: 1,
      name: "ABC Kitchen, Milwaukee, WI",
      selected: false,
    },
    {
      id: 2,
      name: "ABC Kitchen, Milwaukee, TX",
      selected: false,
    },
    {
      id: 3,
      name: "ABC Kitchen, Milwaukee, VA",
      selected: false,
    },
    {
      id: 4,
      name: "ABC Kitchen, Milwaukee, DE",
      selected: false,
    },
    {
      id: 5,
      name: "ABC Kitchen, Milwaukee, BC",
      selected: false,
    },
    {
      id: 6,
      name: "ABC Kitchen, Milwaukee, YT",
      selected: false,
    },
    {
      id: 7,
      name: "ABC Kitchen, Milwaukee, KO",
      selected: false,
    },
    {
      id: 8,
      name: "ABC Kitchen, Milwaukee, PT",
      selected: false,
    },
    {
      id: 9,
      name: "ABC Kitchen, Milwaukee, XL",
      selected: false,
    },
    {
      id: 10,
      name: "ABC Kitchen, Milwaukee, MD",
      selected: false,
    },
  ];

  searchVendor(keyword: string): void {}

  selectVendor(vendor: any): void {
    this.labelName = vendor.name;
    this.isDropdown = false;
  }

  save(): void {
    this.createNewVendor = false;
  }

  openCreateMenu() {
    this.form.reset();
    this.createNewVendor = true;
  }
}
