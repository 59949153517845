<div class="justify-between align-center mb-30">
  <h1 class="font-24px">{{ menu | titlecase }}</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">
    Save & Exit
  </button>
</div>

<ng-container [ngSwitch]="menu">
  <app-global-form-unit-general
    *ngSwitchCase="'general'"
    [unitDetail]="unitDetail"
    [unitAmenities]="unitAmenities"
    [tab]="tab" />

  <app-container-dialog-set *ngSwitchCase="'photos'">
    <div container>
      <app-file-uploader [config]="modelConfig" [(data)]="attachments" />
    </div>
  </app-container-dialog-set>

  <app-container-dialog-set *ngSwitchCase="'amenities'">
    <div container>
      <p class="pb-20">
        Please select which amenities included in this association
      </p>
      <div class="set-grid set-grid-3">
        <mat-checkbox>
          <span class="fw-600">Label I</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label II</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label III</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label IV</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label V</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label VI</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label VII</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label VIII</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label IX</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label X</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label XI</span>
        </mat-checkbox>
        <mat-checkbox>
          <span class="fw-600">Label XII</span>
        </mat-checkbox>
      </div>
    </div>
  </app-container-dialog-set>
</ng-container>
