<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="true"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container>
    <div class="container-violation-category">
      <label class="fw-500">Violation Categories</label>
      <span class="fw-300"
        >Customize the categories available for violations logged in Lincoln
        Homeowners Association.</span
      >
      <div class="mt-10">
        <div
          class="roam-accordion-list"
          *ngFor="let category of listCategory; let category_i = index"
        >
          <mat-accordion class="roam-accordion">
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="header-center">
                    <div class="align-center">
                      <div class="ml-12 align-center gap-12">
                        <div class="font-18px fw-500">
                          {{ category.categoryName | titlecase }} ({{
                            category.ListType.length + 1
                          }})
                        </div>
                      </div>
                    </div>
                    <div class="d-flex gap-8">
                      <div
                        class="d-flex mt-4 caret"
                        [inlineSVG]="iconButton.caretTop.src"
                      ></div>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="description">
                <div *ngFor="let data of category.ListType; let data_i = index">
                  <div class="list-detail">
                    <span class="fw-500">{{
                      data.violationName | titlecase
                    }}</span>
                    <!-- action -->
                    <button
                      aria-label="show card menu"
                      [matMenuTriggerFor]="tableMenu"
                      [matMenuTriggerData]="{ index: data_i }"
                      class="more-button"
                      mat-icon-button
                      type="button"
                    >
                      <mat-icon>more_horiz</mat-icon>
                    </button>
                  </div>
                  <hr />
                </div>
                <div class="mt-20">
                  <app-button-add
                    [round]="true"
                    [label]="'Add Type'"
                    (onAdd)="addType(category_i)"
                  ></app-button-add>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <app-button-add
        [round]="true"
        [label]="'Add Category'"
        (onAdd)="addCategory()"
      ></app-button-add>
    </div>
  </ng-container>
</app-container-dialog-set>

<mat-menu class="board-card-menu" #tableMenu="matMenu">
  <ng-template matMenuContent let-index="index">
    <button mat-menu-item (click)="editType(index)">
      <div class="align-center gap-8 font-14 fw-500">
        <div class="d-flex" [inlineSVG]="'./assets/svg/edit-black.svg'"></div>
        <div>Edit</div>
      </div>
    </button>
    <button mat-menu-item (click)="deleteType(index)">
      <div class="delete-btn align-center gap-8 font-14 fw-500">
        <div
          class="d-flex"
          [inlineSVG]="'./assets/svg/trash-red-icon.svg'"
        ></div>
        <div>Delete</div>
      </div>
    </button>
  </ng-template>
</mat-menu>
