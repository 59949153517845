<div>
  <app-global-form-tabs
    (tabSelectedChange)="checkForFormDirty()"
    [tabs]="tabs"
    [(tabSelected)]="tab"
  />

  <hr class="mb-2rem" />

  <ng-container [ngSwitch]="tab">
    <app-global-form-association-general-addresses
      *ngSwitchCase="'addresses'"
      [associationId]="associationDetail?.id"
      [associationAddresses]="associationDetail?.addresses"
    />

    <app-global-form-association-general-property-types
      *ngSwitchCase="'property types'"
      [associationId]="associationDetail?.id"
      [associationPropertyTypes]="associationDetail"
    />

    <app-global-form-association-general-documents
      *ngSwitchCase="'documents'"
      [associationId]="associationDetail?.id"
      [associationDocuments]="associationDetail"
    />

    <app-global-form-association-general-photos
      *ngSwitchCase="'photos'"
      [associationId]="associationDetail?.id"
    />

    <app-global-form-association-general-amenities
      *ngSwitchCase="'amenities'"
      [associationId]="associationDetail?.id"
      [associationAmenities]="associationAmenities"
    />

    <app-global-form-association-general-contacts
      *ngSwitchCase="'contact'"
      [associationId]="associationDetail?.id"
      [associationPhones]="associationDetail?.phones"
      [associationEmails]="[]"
    />

    <app-global-form-association-general-information
      *ngSwitchCase="'information'"
      [associationId]="associationDetail?.id"
      [associationDetail]="associationDetail"
    />
  </ng-container>
</div>
