<ng-template #defaultEmptyTemplate>
  <app-empty-state title="Nothing to display" subtitle="No users available" />
</ng-template>

<div class="roam-mdc-progress-bar-wrapper">
  @if (isLoading() || isSubmitting()) {
    <mat-progress-bar mode="indeterminate" />
  }
</div>

<table
  matSort
  mat-table
  matSortDisableClear
  [dataSource]="dataSource()"
  [matSortActive]="opt.sortActive"
  [matSortDirection]="opt.sortDirection"
  (matSortChange)="onSortChange($event)">
  <ng-container [matColumnDef]="colDef.Index">
    <th
      *matHeaderCellDef
      class="cell-header-index"
      mat-header-cell
      mat-sort-header>
      #
    </th>
    <td class="cell-index" mat-cell *matCellDef="let i = index">
      {{
        paginatorPageIndex === 0 ?
          i + 1
        : 1 + i + paginatorPageIndex * paginatorPageSize
      }}
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.CreatedDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Created Date</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.createdAt | date: dateFmt }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">
      <a (click)="viewRow.emit(row)" class="roam-text-link">
        {{ row.name || "N/A" }}
      </a>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.NickName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Nick Name</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <a>{{ row.nameNickname || "N/A" }}</a>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.FirstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">First Name</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.nameFirst || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.MiddleName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Middle Name</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.nameMiddle || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.LastName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Last Name</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.lastName || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.PronounciationName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Name Pronouciation</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.namePronunciation || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.CompanyName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Company Name</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <a class="roam-text-link">{{ row.nameCompany || "N/A" }}</a>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.address || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Email Address</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <p class="email-cell roam-line-clamp-1">{{ row.email || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.PhoneNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Phone Number</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <p class="roam-line-clamp-1">{{ row.contactPhone || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.ContactTypeSelection">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Contact Type</span>
    </th>
    <td mat-cell *matCellDef="let row; let i = index">
      <mat-form-field appearance="outline" class="roam-mdc-form-field">
        <mat-label>Contact Type</mat-label>
        <mat-select
          [(value)]="row.roleName"
          (selectionChange)="onContactTypeSelectChange(i, row, $event)"
          panelClass="roam-mdc-select-panel contact-type-select-panel">
          <mat-option class="roam-mdc-option" value="Tenant">Tenant</mat-option>
          <mat-option class="roam-mdc-option" value="Owner">Owner</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.RemoveRow">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Remove</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <button
        (click)="removeRow.emit(row[modelKey])"
        mat-icon-button
        matTooltip="Remove user"
        color="warn"
        type="button">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.StatusName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let row">
      @switch (row.statusName?.toLowerCase()) {
        @case ("active") {
          <chip-ui status="success" size="sm" rounded="sm">Active</chip-ui>
        }
        @case ("inactive") {
          <chip-ui status="danger" size="sm" rounded="sm">Inactive</chip-ui>
        }
        @default {
          <chip-ui size="sm" rounded="sm" status="basic">Unknown</chip-ui>
        }
      }
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.PortalStatusName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Portal Status</span>
    </th>
    <td mat-cell *matCellDef="let row">
      @switch (row.portalStatusName?.toLowerCase()) {
        @case ("enabled") {
          <chip-ui status="success" size="sm" rounded="sm">Enabled</chip-ui>
        }
        @case ("off") {
          <chip-ui status="warning" size="sm" rounded="sm">Off</chip-ui>
        }
        @default {
          <chip-ui size="sm" rounded="sm" status="basic">Unknown</chip-ui>
        }
      }
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Selections">
    <th class="cell-selection" mat-header-cell *matHeaderCellDef>
      <div class="content-center">
        <mat-checkbox
          [checked]="selections.isAllSelected"
          (change)="selections.toggleAll()" />
      </div>
    </th>
    <td class="cell-selection" mat-cell *matCellDef="let row">
      <div class="content-center">
        <mat-checkbox
          [checked]="selections.isSelected(row[modelKey])"
          (change)="selections.toggleOne(row[modelKey])"
          [class.visible]="selections.isSelected(row[modelKey])"
          class="hover-visible" />
      </div>
    </td>
  </ng-container>

  <tr mat-header-row class="table-header" *matHeaderRowDef="columnDefs()"></tr>

  <tr
    mat-row
    class="table-row"
    contentVisibleHoverUi
    childSelector=".hover-visible"
    [class.table-row-selected]="selections.isSelected(row[modelKey])"
    *matRowDef="let row; columns: columnDefs(); index as i"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="columnDefs().length">
      <ng-container *ngTemplateOutlet="emptyView() || defaultEmptyTemplate" />
    </td>
  </tr>
</table>

@if (!isEmpty() && showPagination()) {
  <mat-paginator
    [length]="total()"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageIndex]="opt.pageIndex - 1"
    [pageSize]="opt.pageSize"
    (page)="onPage($event)"
    aria-label="Select page of users" />
}
