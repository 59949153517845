import { Component, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AssociationService } from "@app/shared/services/association.service";

@Component({
  selector: "app-global-form-association-general-amenities",
  templateUrl: "./global-form-association-general-amenities.component.html",
  styles: [
    `
      .boldText {
        font-weight: bold;
      }
    `,
  ],
})
export class GlobalFormAssociationGeneralAmenitiesComponent {
  @Input()
  public associationId?: string;

  @Input("associationAmenities")
  public set setAssociationAmenities(associationAmenities: any | undefined) {
    if (associationAmenities) this.generateForm(associationAmenities);
  }

  public form: FormGroup;

  constructor(
    private associationService: AssociationService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({});
  }

  private generateForm(associationAmenities: any) {
    for (let key in associationAmenities) {
      this.form.addControl(key, new FormControl(associationAmenities[key]));
    }
  }

  public submit() {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association General Amenities Edit");

      const transformedData = {
        values: Object.entries(formData).map(([key, value]) => ({
          key,
          value,
        })),
      };

      this.associationService
        .saveAssociationServices(this.associationId, transformedData)
        .subscribe();
    } else {
      console.log("Association General Amenities Add");
    }

    console.log("DATA", formData);
  }
}
