<div>
  <button
    class="btn btn-o-primary"
    cdkOverlayOrigin
    #openProperty="cdkOverlayOrigin"
    (click)="openDropdown()"
  >
    <img [src]="icon.plusRed.src" [alt]="icon.plusRed.alt" />
    <span class="ml-8">Add Property</span>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="openProperty"
  [cdkConnectedOverlayOpen]="isDropdown"
  cdkConnectedOverlayPush="true"
  [cdkConnectedOverlayWidth]="320"
  [cdkConnectedOverlayPositions]="dropdownPosition"
  [cdkConnectedOverlayViewportMargin]="7"
  (backdropClick)="closeDropdown()"
>
  <div class="dropdown pl-16 pr-16 pt-8 pb-0">
    <div class="pt-8 pb-8">
      <app-input-search-one (onSearch)="searchProperty($event)" />
    </div>

    <div class="fw-600 pt-10 pb-10">
      ({{ SELECTED_PROERTY().length }}) Selected
    </div>
    @if(isListLoading()){
    <mat-spinner class="spinner-style"></mat-spinner>
    }@else {
    <div class="gutter scroll-container">
      <div class="reverse hovered round-checkbox">
        @for (property of LIST_PROPERTY(); track property.id) {
        <mat-checkbox
          [checked]="checkStatus(property)"
          [disabled]="checkStatus(property)"
          [class]="checkStatus(property) ? 'disable-check' : ''"
          (change)="selectProperty(property, $event)"
        >
          <div class="list-property">
            <img
              class="property-icon"
              [src]="property.imageUrl ?? 'assets/svg/ic-image.svg'"
              [alt]="property.name"
            />
            <div class="detail-property">
              <span class="title">{{ property.name }}</span>
              <span class="desc"
                >{{ property.city }}, {{ property.state }}</span
              >
            </div>
          </div>
        </mat-checkbox>
        }
      </div>
    </div>
    }

    <div class="gutter action-button">
      <span (click)="closeDropdown()" class="text-btn-underline fw-500">
        Cancel
      </span>

      <app-roam-button label="Save" (click)="save()" />
    </div>
  </div>
</ng-template>
