<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <mat-radio-group
      class="d-flex gap-10 align-center mb-20"
      formControlName="cashAccrualBasis"
    >
      <mat-radio-button value="cash">Cash basis</mat-radio-button>

      <mat-radio-button value="accrual">Accrual basis</mat-radio-button>
    </mat-radio-group>

    <div class="set-grid set-grid-2">
      <app-roam-select
        class="text-input"
        formControlName="fiscalYearBeginsMonth"
        placeholder="Fiscal year begins"
        [items]="monthOptions"
      />

      <app-roam-select
        class="text-input"
        formControlName="taxYearBeginsMonth"
        placeholder="Tax year begins"
        [items]="monthOptions"
      />

      <app-roam-select
        class="text-input"
        formControlName="taxForm"
        placeholder="Tax form filed"
        [items]="taxForms"
      />
    </div>
  </ng-container>
</app-container-dialog-set>
