import { Component } from "@angular/core";

@Component({
  selector: "app-global-form-contact-form-emergency",
  templateUrl: "./global-form-contact-form-emergency.component.html",
})
export class GlobalFormContactFormEmergencyComponent {
  public addContactForm(): void {}

  public submit(): void {}
}
