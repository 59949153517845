import { DestroyRef, inject, Injectable, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ASYNC_STATE, TAsyncState } from "@app/pages/task/+data-access";
import { RoamTableOptions } from "@app/utils";
import { CustomerApiService } from "./customer-api.service";
import { CustomerAccount } from "./models/customer";
import { CustomerReqBody } from "./models/customer-req";

@Injectable()
export class CustomerAccountStore {
  #destroyRef = inject(DestroyRef);
  #api = inject(CustomerApiService);

  readonly asyncProgress = signal<TAsyncState>(ASYNC_STATE.IDLE);
  readonly data = signal<CustomerAccount[]>([]);
  readonly total = signal<number>(0);
  readonly params = signal<RoamTableOptions>({
    pageSize: 5,
    pageIndex: 1,
    sortActive: "",
    sortDirection: "",
  });

  setData = (users: CustomerAccount[]) => {
    this.data.set(users);
  };

  getList(params: CustomerReqBody.SearchMany): void {
    this.asyncProgress.set("loading");
    this.#api
      .getCustomerAccounts(params)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: resp => {
          console.warn(resp);
          this.data.set((resp.data as any) || []);
          this.total.set(resp.meta?.total || 0);
          this.asyncProgress.set("loaded");
        },
        error: () => {
          this.asyncProgress.set("error");
        },
      });
  }
}
