<div>
  <app-global-form-tabs [tabs]="tabs" [(tabSelected)]="tab" />

  <hr class="mb-2rem" />

  <ng-container [ngSwitch]="tab">
    <app-global-form-association-accounting-accounts
      *ngSwitchCase="'accounts'"
      [accountOptions]="accountingOptions.accounts"
      [associationAccounting]="associationAccounting"
      [associationId]="associationDetail?.id"
    />

    <app-global-form-association-accounting-assessments
      *ngSwitchCase="'assessments'"
    />

    <app-global-form-association-accounting-collections
      *ngSwitchCase="'collections'"
      [associationAccounting]="associationAccounting"
      [associationId]="associationDetail?.id"
      [itemOptions]="accountingOptions.items"
    />

    <app-global-form-association-accounting-dues
      *ngSwitchCase="'dues'"
      [associationAccounting]="associationAccounting"
      [associationId]="associationDetail?.id"
      [itemOptions]="accountingOptions.items"
    />

    <app-global-form-association-accounting-options
      *ngSwitchCase="'options'"
      [associationAccounting]="associationAccounting"
      [associationId]="associationDetail?.id"
      [paymentMethodOptions]="accountingOptions.methods"
      [paymentTermOptions]="accountingOptions.terms"
    />

    <app-global-form-association-accounting-reports
      *ngSwitchCase="'reports'"
      [associationAccounting]="associationAccounting"
      [associationId]="associationDetail?.id"
      [termsOptions]="accountingOptions.terms"
    />
  </ng-container>
</div>
