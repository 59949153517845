import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AssociationService } from "@app/shared/services/association.service";
import { Subject, takeUntil } from "rxjs";

interface IFile {
  name: string;
  type: string;
  date: string;
  user: string;
  size: number;
  file: object;
}
@Component({
  selector: "app-global-form-association-general-photos",
  templateUrl: "./global-form-association-general-photos.component.html",
  styleUrls: ["./global-form-association-general-photos.component.scss"],
})
export class GlobalFormAssociationGeneralPhotosComponent {
  @Input()
  public associationId?: string;

  private destroy$ = new Subject<void>();
  public form: FormGroup;
  public files: IFile[] = [
    {
      name: "File Name.pdf",
      type: "pdf",
      date: "April 30, 2023",
      user: "Rowdy",
      size: 1800,
      file: {},
    },
    {
      name: "File Name 2.pdf",
      type: "pdf",
      date: "April 30, 2023",
      user: "Rowdy",
      size: 12000,
      file: {},
    },
  ];

  uploadFile(file: File): void {
    const { name, size, lastModified } = file;

    const newFile: IFile = {
      name: name,
      size: size,
      date: new Date(lastModified).toString(),
      user: "User",
      file: file,
      type: "",
    };
    this.files.push(newFile);
    this.form.controls["files"].setValue(this.files);
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.form.controls["files"].setValue(this.files);
  }

  close(): void {}

  submit(): void {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association General Photos Edit");

      const transformedData = {
        values: Object.entries(formData).map(([key, value]) => ({
          key,
          value,
        })),
      };

      this.associationService
        .saveAssociationServices(this.associationId, transformedData)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    } else {
      console.log("Association General Photos Add");
    }

    console.log("DATA", formData);
  }

  constructor(
    private associationService: AssociationService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      files: new FormArray([]),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
