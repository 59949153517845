<app-container-dialog-set (onClose)="close()" (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <div class="photo-card">
      @for (file of files;let file_i = $index;track file_i ) {
      <div class="photo-body">
        <mat-icon
          class="photo-close-icon"
          (click)="deleteFile(file_i)"
          fontSet="material-icons-outlined"
        >
          close
        </mat-icon>
        <img src="/assets/images/home-page-swiper/screenshot-1.png" alt="" />
        <div class="photo-btn-label">Add Label</div>
      </div>
      }
      <app-attachment-upload-button
        class="btn-upload-file"
        (onUploadedFiles)="uploadFile($event)"
      ></app-attachment-upload-button>
    </div>
  </ng-container>
</app-container-dialog-set>
