import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { dialogConfig } from "@app/core/const/dialog.const";
import { configmDelete } from "@app/pages/associations/shared/const/association-dialog.const";
import { ExpandAccordion } from "@app/shared/class/expand-accordion.class";
import { ConfirmationDialogComponent } from "@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component";
import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";

interface IViolationsStage {
  id: string;
  stageName: string;
  notificationTemplate: string;
  edit: boolean;
}

@Component({
  selector: "app-global-form-association-compliance-violations-stages",
  templateUrl:
    "./global-form-association-compliance-violations-stages.component.html",
  styleUrls: [
    "./global-form-association-compliance-violations-stages.component.scss",
  ],
})
export class GlobalFormAssociationComplianceViolationsStagesComponent {
  public expand: ExpandAccordion = new ExpandAccordion();
  public form!: FormGroup;
  public listData: IViolationsStage[] = [
    {
      id: "111",
      stageName: "name 1",
      notificationTemplate: "name 1",
      edit: false,
    },
    {
      id: "111",
      stageName: "name 2",
      notificationTemplate: "name 2",
      edit: false,
    },
    {
      id: "111",
      stageName: "name 3",
      notificationTemplate: "name 3",
      edit: false,
    },
    {
      id: "111",
      stageName: "name 4",
      notificationTemplate: "name 4",
      edit: false,
    },
    {
      id: "111",
      stageName: "name 5",
      notificationTemplate: "name 5",
      edit: false,
    },
  ];

  addStages(): void {
    const newStages: IViolationsStage = {
      id: "0",
      stageName: "",
      notificationTemplate: "",
      edit: true,
    };

    this.listData.push(newStages);
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.listData, event.previousIndex, event.currentIndex);
  }

  onEdit(i: number): void {
    this.listData[i].edit = !this.listData[i].edit;
  }

  onDelete(i: number): void {
    const confirmationDialogText: IConfirmationDialog = configmDelete;

    this.dialog
      .open(ConfirmationDialogComponent, {
        ...dialogConfig.confirm,
        data: {
          confirmation: confirmationDialogText,
        },
      })
      .afterClosed()
      .subscribe(val => {
        if (val) {
          this.listData.splice(i, 1);
        }
      });
  }

  submit(): void {}

  close(): void {}

  constructor(public dialog: MatDialog) {}
}
