import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AccountingService } from "@app/shared/services/accounting.service";

@Component({
  selector: "app-global-form-association-accounting-accounts",
  templateUrl: "./global-form-association-accounting-accounts.component.html",
})
export class GlobalFormAssociationAccountingAccountComponent {
  @Input()
  public accountOptions?: any[];

  @Input("associationAccounting")
  public set setAssociationAccounting(associationAccounting: any | undefined) {
    if (associationAccounting) this.patchForm(associationAccounting);
  }

  @Input()
  public associationId?: string;

  public form: FormGroup;

  constructor(
    private accountingService: AccountingService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.createForm();
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      accountsPayableAccountId: [""],
      accountsReceivableAccountId: [""],
      badDebtExpenseAccountId: [""],
      bankAccountId: [""],
      bankFeeAccountId: [""],
      checkingAccountId: [""],
      depositAccountId: [""],
      discrepancyReconciliationAccountId: [""],
      interestAccountId: [""],
      priorYearCollectedAccountId: [""],
      retainedEarningsAccountId: [""],
      uncollectedDuesAccountId: [""],
      uncollectedOtherAccountId: [""],
    });
  }

  private patchForm(associationAccounting: any) {
    this.form.patchValue({ ...associationAccounting });
  }

  public submit() {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association Accounting Accounts Edit");

      this.accountingService
        .saveAssociationAccounting(this.associationId, formData)
        .subscribe();
    } else {
      console.log("Association Accounting Accounts Add");
    }

    console.log("DATA", formData);
  }
}
