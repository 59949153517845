import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputDropdownComponent } from "@app/shared/components/input/input-dropdown/input-dropdown.component";
import { ConnectionPositionPair, OverlayModule } from "@angular/cdk/overlay";
import { dropdownPosition } from "@app/core/const/overlay.const";
import { InputSearchOneComponent } from "@app/shared/components/input/input-search-one/input-search-one.component";
import { MatIconModule } from "@angular/material/icon";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { iconLib } from "@app/core/const/roam-icon";
import { InputModule } from "@app/shared/components/input/input.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InlineSVGModule } from "ng-inline-svg-2";
import { MatDialog } from "@angular/material/dialog";
import { DialogCreateNewArchitecturalComponent } from "../dialog-create-new/dialog-create-new.component";
import { dialogConfig } from "@app/core/const/dialog.const";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputDropdownComponent,
    OverlayModule,
    FormsModule,
    InputSearchOneComponent,
    MatIconModule,
    RoamButtonComponent,
    InputModule,
    InlineSVGModule,
  ],
  selector: "contact-select-architectural-dropdown",
  templateUrl: "./select-architectural-dropdown.component.html",
  styleUrls: ["./select-architectural-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectArchitecturalDropdownComponent {
  icon = iconLib;
  isDropdown!: boolean;
  dropdownPosition: ConnectionPositionPair[] = dropdownPosition;
  labelName: string = "";

  SAMPLE_Architectural: any[] = [
    {
      id: "1",
      imgUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4CBB3Pw-V7gi9hzWmEITIgtA53Bh4PH54v0Eu8qz2gQ&s",
      name: "ABC Kitchen, Milwaukee, WI",
      desc: "ABC Kitchen, Milwaukee, WI",
    },
    {
      id: "2",
      imgUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4CBB3Pw-V7gi9hzWmEITIgtA53Bh4PH54v0Eu8qz2gQ&s",
      name: "ABC Kitchen, Milwaukee, TX",
      desc: "ABC Kitchen, Milwaukee, WI",
    },
    {
      id: "3",
      imgUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4CBB3Pw-V7gi9hzWmEITIgtA53Bh4PH54v0Eu8qz2gQ&s",
      name: "ABC Kitchen, Milwaukee, VA",
      desc: "ABC Kitchen, Milwaukee, WI",
    },
    {
      id: "4",
      imgUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4CBB3Pw-V7gi9hzWmEITIgtA53Bh4PH54v0Eu8qz2gQ&s",
      name: "ABC Kitchen, Milwaukee, DE",
      desc: "ABC Kitchen, Milwaukee, WI",
    },
  ];

  searchArchitectural(keyword: string): void {}

  clearLabel(): void {
    this.labelName = "";
  }
  openCreateMenu(): void {
    this.dialog
      .open(DialogCreateNewArchitecturalComponent, {
        ...dialogConfig.addDialogArcitectural,
        data: {},
      })
      .afterClosed()
      .subscribe();
  }

  selectData(data: any): void {
    this.labelName = data.name;
    this.isDropdown = false;
  }
  constructor(private dialog: MatDialog) {}
}
