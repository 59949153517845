<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <div class="set-grid set-grid-3 pt-10">
      <app-roam-input formControlName="doorRate" name="Door Rate" />

      <app-roam-input formControlName="monthlyMinimum" name="Monthly Minimum" />

      <app-roam-input
        formControlName="contractExpires"
        name="Contract Expires"
      />
    </div>
  </ng-container>
</app-container-dialog-set>
