export const TOASTR_MESSAGE_COMPLIANCE_VIOLATIONS_CATEGORY = {
  SUCCESS_ADD_CATEGORY: "Successfully add category",
  SUCCESS_EDIT_CATEGORY: "Successfully edit category",
  SUCCESS_DELETE_CATEGORY: "Successfully delete category",
  SUCCESS_ADD_TYPE: "Successfully add type",
  SUCCESS_EDIT_TYPE: "Successfully edit type",
  SUCCESS_DELETE_TYPE: "Successfully delete type",

  FAILED_ADD_CATEGORY: "Failed add category",
  FAILED_EDIT_CATEGORY: "Failed edit category",
  FAILED_DELETE_CATEGORY: "Failed delete category",
  FAILED_ADD_TYPE: "Failed add type",
  FAILED_EDIT_TYPE: "Failed edit type",
  FAILED_DELETE_TYPE: "Failed delete type",
} as const;

export const TOASTR_MESSAGE_EMPLOYEE_INFO = {
  SUCCESS_ADD_EMPLOYEE: "Success add employee",
  SUCCESS_EDIT_EMPLOYEE: "Success edit employee",

  FAILED_ADD_EMPLOYEE: "Failed add employee",
  FAILED_EDIT_EMPLOYEE: "Failed edit employee",
} as const;

export const TOASTR_MESSAGE_GENERAL_INFO = {
  SUCCESS_ADD_GENERAL_INFO: "Success add general info",
  SUCCESS_EDIT_GENERAL_INFO: "Success edit general info",

  FAILED_ADD_GENERAL_INFO: "Failed add general info",
  FAILED_EDIT_GENERAL_INFO: "Failed edit general info",
} as const;

export const TOASTR_MESSAGE_GENERAL_ADDRESS = {
  SUCCESS_ADD_GENERAL_ADDRESS: "Successfully add General Address",
  SUCCESS_EDIT_GENERAL_ADDRESS: "Successfully edit General Address",

  FAILED_ADD_GENERAL_ADDRESS: "Failed add general address",
  FAILED_EDIT_GENERAL_ADDRESS: "Failed edit general Address",
} as const;

export const TOASTR_MESSAGE_ARCHITECTURAL = {
  SUCCESS_ADD: "Successfully add",
  SUCCESS_EDIT: "Successfully edit",

  FAILED_ADD: "Failed to add",
  FAILED_EDIT: "Failed to edit",
} as const;

export const TOASTR_MESSAGE_CONTACT_PROPERTY = {
  SUCCESS_ADD: "Successfully add",
  SUCCESS_REMOVE: "Successfully remove",
  SUCCESS_EDIT: "Successfully edit",

  FAILED_ADD: "Failed to add",
  FAILED_EDIT: "Failed to edit",
  FAILED_REMOVE: "Failed to remove",
} as const;
