<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Vendor Info</h1>
  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set
  [isValid]="true"
  (onClose)="close()"
  (onSave)="formSubmit.emit()"
>
  <form container [formGroup]="form()">
    <div class="gap-24 align-center">
      <div>
        <app-image-view
          [width]="'80px'"
          [height]="'80px'"
          [rounded]="true"
          [placeholderImage]="'user-grey'"
        ></app-image-view>
      </div>
      <div>
        <div class="font-14px fw-400 tc-dust mb-5">
          SVG, PNG, JPG (max. 300x300px)
        </div>
        <div>
          <app-button-file
            label="Upload Photo"
            [class]="'btn btn-o-primary'"
            [icon]="icon.plusRed.src"
          ></app-button-file>
        </div>
      </div>
    </div>

    <div class="set-grid set-grid-2 mt-30">
      <app-roam-input formControlName="name" name="name" />
      <contact-select-vendor-dropdown />
      <app-roam-select placeholder="Select a vendor Type" />
      <app-roam-input name="Phone Number" />
      <app-roam-input name="Website" />
    </div>

    <div class="mt-24">
      <app-roam-text-area name="Notes" />
    </div>

    <hr />

    <h1 class="font-20px">Portal Login Info</h1>

    <div class="set-grid set-grid-2 mt-30">
      <app-roam-input formControlName="email" name="Primary Email" />
      <app-roam-input name="Mobile Phone" />
      <app-roam-toggle-slider
        label="Invite this tenant to the Tenant Portal"
      />
    </div>
  </form>
</app-container-dialog-set>
