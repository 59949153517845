import { CurrencyPipe, DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  output,
  ViewEncapsulation,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ChipUi } from "@app/pages/customer/+ui";
import { ContentVisibleHoverUi } from "@app/pages/job/+ui";
import { ButtonActionComponent } from "@app/shared/components/button/button-action/button-action.component";
import { ImageViewComponent } from "@app/shared/components/roam-layout/image-view/image-view.component";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { IUser } from "@app/shared/interfaces";
import {
  ROAM_TABLE_BASE_MODULES,
  ROAM_TABLE_COL_DEF_BASE,
  RoamTableAccessor,
} from "@app/utils/table.utils";
import { ExtractEnum } from "@app/utils/typing.utils";
import { InlineSVGModule } from "ng-inline-svg-2";

export const COL_DEF_USER = {
  ...ROAM_TABLE_COL_DEF_BASE,
  CreatedDate: "createdAt",
  Name: "name",
  NickName: "nickName",
  FirstName: "firstName",
  MiddleName: "middleName",
  LastName: "lastName",
  PronounciationName: "pronounciationName",
  CompanyName: "companyName",
  NamePhotoAndEmail: "namePhotoAndEmail",
  Address: "address",
  Email: "email",
  PhoneNumber: "phoneNumber",
  StatusName: "statusName",
  PortalStatusName: "portalStatusName",
  ContactTypeSelection: "contactTypeSelection",
  RemoveRow: "removeRow",
} as const;

export type ColDefUser = ExtractEnum<typeof COL_DEF_USER>;

@Component({
  standalone: true,
  selector: "app-users-table",
  styleUrl: "./users-table.cmp.scss",
  templateUrl: "users-table.cmp.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    ...ROAM_TABLE_BASE_MODULES,
    RoamCardComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    ButtonActionComponent,
    InlineSVGModule,
    ContentVisibleHoverUi,
    ImageViewComponent,
    ChipUi,
    DatePipe,
    CurrencyPipe,
  ],
})
export class UsersTableCmp extends RoamTableAccessor<IUser, ColDefUser> {
  protected dateFmt = "dd/MM/yyyy";
  readonly removeRow = output<typeof this.modelKey>();
  readonly viewRow = output<IUser>();

  protected get opt() {
    return this.options();
  }

  protected get colDef() {
    return COL_DEF_USER;
  }

  onContactTypeSelectChange(i: number, row: IUser, e: MatSelectChange): void {
    this.rowChange.emit({
      rowIndex: i,
      rowData: row,
      changes: {
        roleName: e.value,
      },
    });
  }

  constructor() {
    super({ modelKey: "id" });
  }
}
