<ng-container [ngSwitch]="menu">
  <app-global-form-contact-form-address *ngSwitchCase="'address'" />

  <app-global-form-contact-form-contact *ngSwitchCase="'contact'" />

  <app-global-form-contact-form-document *ngSwitchCase="'document'" />

  <app-global-form-contact-form-insurance *ngSwitchCase="'insurance'" />

  <app-global-form-contact-form-personal
    *ngSwitchCase="'personal'"
    [form]="controls.personal"
    (formSubmit)="onPersonalSubmit()"
  />
</ng-container>
