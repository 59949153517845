import { Component, signal } from "@angular/core";
import { iconLib } from "../../../../../../core/const/roam-icon";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { TOASTR_MESSAGE_EMPLOYEE_INFO } from "../../../const";
import { ToastrService } from "ngx-toastr";
import { ROAM_TOASTR } from "@app/core/const/app-roam.const";

interface IUser {
  firstName: string;
  lastName: string;
  groupName: string;
  jobTitle: string;
  phone: string;
  email: string;
}

const MOCKUP_DATA: IUser[] = [
  {
    email: "Rommy@gmail.com",
    firstName: "Rommy",
    lastName: "Gautanto",
    groupName: "TESTING",
    jobTitle: "Dev",
    phone: "08123123",
  },
  {
    email: "Radvil@gmail.com",
    firstName: "Radvil",
    lastName: "Laode",
    groupName: "TESTING",
    jobTitle: "Dev",
    phone: "08123123",
  },
];

@Component({
  selector: "app-global-form-contact-form-employee",
  templateUrl: "./global-form-contact-form-employee.component.html",
  styleUrls: ["./global-form-contact-form-employee.component.scss"],
})
export class GlobalFormContactTenantEmployeeComponent {
  icon = iconLib;
  form!: FormGroup;
  isLoading = signal<boolean>(false);

  private TOS_TEXT = TOASTR_MESSAGE_EMPLOYEE_INFO;

  get formEmployees(): FormArray {
    return this.form.get("employees") as FormArray;
  }

  createFormEmployee(index: number): FormGroup {
    return this.fb.group({
      title: [`Employee ${index + 1}`],
      firstName: [""],
      lastName: [""],
      groupName: [""],
      jobTitle: [""],
      phone: [""],
      email: [""],
    });
  }

  addEmployee(): void {
    const number = this.formEmployees.length;

    this.formEmployees.push(this.createFormEmployee(number));
  }

  removeEmployee(index: number): void {
    this.formEmployees.removeAt(index);
  }

  submit(): void {
    //TODO: Submit to API
    this.toastr.success(this.TOS_TEXT.SUCCESS_ADD_EMPLOYEE, "", {
      ...ROAM_TOASTR.center,
    });
  }

  close(): void {
    //TODO: Check form valid before close the dialog
    this.dialogRef.close();
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<GlobalFormContactTenantEmployeeComponent>,
    private toastr: ToastrService,
  ) {
    this.form = this.fb.group({
      employees: this.fb.array([this.createFormEmployee(0)]),
    });
  }
}
