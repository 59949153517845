import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AccountingService } from "@app/shared/services/accounting.service";

@Component({
  selector: "app-global-form-association-accounting-reports",
  templateUrl: "./global-form-association-accounting-reports.component.html",
})
export class GlobalFormAssociationAccountingReportsComponent {
  @Input("associationAccounting")
  public set setAssociationAccounting(associationAccounting: any | undefined) {
    if (associationAccounting) this.patchForm(associationAccounting);
  }

  @Input()
  public associationId?: string;

  @Input()
  public termsOptions?: any[];

  public form: FormGroup;
  public monthOptions = [
    { name: "January", id: "1" },
    { name: "February", id: "2" },
    { name: "March", id: "3" },
    { name: "April", id: "4" },
    { name: "May", id: "5" },
    { name: "June", id: "6" },
    { name: "July", id: "7" },
    { name: "August", id: "8" },
    { name: "September", id: "9" },
    { name: "October", id: "10" },
    { name: "November", id: "11" },
    { name: "December", id: "12" },
  ];
  public taxForms = [{ name: "Form 1120", id: "1120" }];

  constructor(
    private accountingService: AccountingService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.createForm();
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      cashAccrualBasis: [""],
      fiscalYearBeginsMonth: [],
      taxForm: [""],
      taxYearBeginsMonth: [],
    });
  }

  private patchForm(associationAccounting: any) {
    this.form.patchValue({ ...associationAccounting });
  }

  public submit() {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association Accounting Reports Edit");

      this.accountingService
        .saveAssociationAccounting(this.associationId, formData)
        .subscribe();
    } else {
      console.log("Association Accounting Reports Add");
    }

    console.log("DATA", formData);
  }
}
