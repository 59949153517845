<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="form.valid && !isLoading()"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container [formGroup]="form">
    <div class="set-grid set-grid-2 pt-10">
      <app-roam-input formControlName="name" name="Association Name" />

      <app-roam-input formControlName="fullName" name="Legal Name" />

      <app-roam-input formControlName="slug" name="Slug" />

      <app-roam-select
        formControlName="languageId"
        placeholder="Language"
        [items]="options.languages"
      />

      <app-roam-select
        formControlName="currencyId"
        placeholder="Currency"
        [items]="options.currencies"
      />

      <app-roam-select
        formControlName="timezoneId"
        placeholder="Timezone"
        [items]="options.timezones"
      />

      <app-roam-input formControlName="latitude" name="Latitude" />
      <app-roam-input formControlName="longitude" name="Longitude" />
      <app-roam-input formControlName="address" name="Physical Address" />
      <app-roam-input formControlName="address2" name="Address 2" />
      <app-roam-input formControlName="city" name="City" />
      <app-roam-input formControlName="state" name="State" />
      <app-roam-input formControlName="zip" name="Zip" />
      <app-roam-input formControlName="country" name="Country" />
    </div>
  </ng-container>
</app-container-dialog-set>
