<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="form.valid && !isLoading()"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container [formGroup]="form">
    <div
      class="card-group"
      formArrayName="addresses"
      *ngFor="let address of formAddresses.controls; let formIndex = index"
    >
      <ng-container [formGroupName]="formIndex">
        <div class="align-center gap-10">
          <label *ngIf="!isEditLabel">
            {{ formAddresses.value[formIndex].name }}
          </label>

          <app-roam-input
            *ngIf="isEditLabel"
            formControlName="name"
            [floatingLabel]="false"
          />

          <div class="mt-5 c-pointer">
            <img
              *ngIf="!isEditLabel"
              [src]="icon.editBlack.src"
              [alt]="icon.editBlack.alt"
              (click)="editLabel()"
            />

            <div *ngIf="isEditLabel">
              <img
                [src]="icon.closeRound.src"
                [alt]="icon.closeRound.alt"
                (click)="isEditLabel = !isEditLabel"
              />

              <img
                [src]="icon.checkRound.src"
                [alt]="icon.checkRound.alt"
                (click)="isEditLabel = !isEditLabel"
              />
            </div>
          </div>
        </div>

        <div class="set-grid set-grid-2 mt-20">
          <app-roam-input formControlName="address" name="Address" />

          <app-roam-input formControlName="address2" name="Address 2" />

          <app-roam-input formControlName="city" name="City" />

          <app-roam-input formControlName="state" name="State" />

          <app-roam-input formControlName="country" name="Country" />

          <app-roam-input formControlName="zip" name="Zip Code" />
        </div>
      </ng-container>
    </div>

    <div class="mt-24">
      <button class="btn btn-o-primary" (click)="addFormAddress()">
        <img [src]="icon.plusRed.src" [alt]="icon.plusRed.alt" />

        <span class="ml-8">Add Address</span>
      </button>
    </div>
  </ng-container>
</app-container-dialog-set>
