<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Insurance</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <app-roam-input [name]="'Provider'"></app-roam-input>

    <div class="set-grid set-grid-2 gap-24 mt-24">
      <app-roam-input [name]="'Policy Name'"></app-roam-input>

      <app-roam-input [name]="'Coverage'"></app-roam-input>

      <app-roam-datepicker [name]="'Effective Date'"></app-roam-datepicker>

      <app-roam-datepicker [name]="'Expiration Date'"></app-roam-datepicker>
    </div>

    <div class="mt-20">
      <h6 class="fw-500 font-14px tc-black mb-8">Attachment</h6>

      <app-attachment-upload></app-attachment-upload>
    </div>
  </ng-container>
</app-container-dialog-set>
