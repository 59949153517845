@switch (menu) {
  @case ("personal") {
    <app-global-form-contact-customer-personal
      [form]="controls.personal"
      (formSubmit)="mergeAndSubmit()" />
  }
  @case ("resident") {
    <app-global-form-contact-form-resident
      [users]="contactDetail?.users || []"
      [form]="controls.resident"
      (formSubmit)="mergeAndSubmit()" />
  }
  @case ("address") {
    <app-global-form-contact-form-address />
  }
  @case ("property") {
    <app-global-form-contact-form-property />
  }
  @case ("document") {
    <app-global-form-contact-form-document />
  }
}
