<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="true"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container>
    <label class="fw-500">Motions</label>
  </ng-container>
</app-container-dialog-set>
