import { Component, Input } from "@angular/core";
import { IAssociationDetail } from '@app/pages/associations/shared/interface';

@Component({
  selector: "app-global-form-association-management",
  templateUrl: "./global-form-association-management.component.html",
})
export class GlobalFormAssociationManagementComponent {
  @Input()
  public associationDetail?: IAssociationDetail;

  @Input()
  public associationManagement?: any;

  @Input("tab")
  public set setTab(tab: string | undefined) {
    this.tab = tab ?? this.tabs[0];
  }

  public tab: string;
  public tabs: string[] = ["team", "rates"];

  constructor() {
    this.tab = this.tabs[0];
  }
}
