import { Component } from "@angular/core";
import { ROAM_IMAGE_SVG } from "../../../../../../core/const/app-roam.const";
import { IRadio } from "../../../../../interfaces/roam-radio.inteface";

@Component({
  selector: "app-global-form-contact-form-payment",
  templateUrl: "./global-form-contact-form-payment.component.html",
})
export class GlobalFormContactTenantPaymentComponent {
  public checked: boolean = false;
  public emailType: string = "recommend";
  public emailOptions: IRadio[] = [
    {
      id: "recommended",
      name: "email",
      label: "Email Recommended",
      value: "recommend",
      icon: `${ROAM_IMAGE_SVG}/il-at.svg`,
    },
    {
      id: "check",
      name: "email",
      label: "Mail Check",
      value: "check",
      icon: `${ROAM_IMAGE_SVG}/il-mail.svg`,
    },
  ];

  public submit(): void {}
}
