<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <div class="set-grid set-grid-2 pt-10">
      <app-roam-select
        class="text-input"
        formControlName="defaultDuesItemId"
        placeholder="Dues Item"
        [items]="itemOptions ?? []"
      />

      <app-roam-select
        class="text-input"
        formControlName="secondaryDuesItemId"
        placeholder="Secondary Dues Item"
        [items]="itemOptions ?? []"
      />

      <app-roam-select
        class="text-input"
        formControlName="openBalanceItemId"
        placeholder="Open Balance Item"
        [items]="itemOptions ?? []"
      />

      <app-roam-select
        class="text-input"
        formControlName="priorChargesItemId"
        placeholder="Prior Charges Item"
        [items]="itemOptions ?? []"
      />
    </div>
  </ng-container>
</app-container-dialog-set>
