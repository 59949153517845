<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Address</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <div class="set-grid set-grid-2 mt-10">
      <app-roam-input [name]="'Street 1'"></app-roam-input>

      <app-roam-input [name]="'Street 2'"></app-roam-input>

      <app-roam-input [name]="'City'"></app-roam-input>

      <app-roam-input [name]="'State'"></app-roam-input>

      <app-roam-input [name]="'Country'"></app-roam-input>

      <app-roam-input [name]="'Zip Code'"></app-roam-input>
    </div>
  </ng-container>
</app-container-dialog-set>
