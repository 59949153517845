<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Personal Info</h1>
  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="formSubmit.emit()">
  <form [formGroup]="form()" container>
    <div class="gap-24 align-center">
      <div>
        <app-image-view
          [width]="'80px'"
          [height]="'80px'"
          rounded
          placeholderImage="user-grey" />
      </div>
      <div>
        <div class="font-14px fw-400 tc-dust mb-5">
          SVG, PNG, JPG (max. 300x300px)
        </div>
        <div>
          <app-button-file
            label="Upload Photo"
            [class]="'btn btn-o-primary'"
            [icon]="icon.plusRed.src" />
        </div>
      </div>
    </div>

    <div class="set-grid set-grid-2 mt-30">
      <app-roam-input formControlName="firstName" name="First Name" required />
      <app-roam-input formControlName="lastName" name="Last Name" />
      <app-roam-input name="Company" />
      <app-roam-input name="Job Title" />
    </div>

    <div class="mt-24">
      <app-roam-text-area name="Notes"></app-roam-text-area>
    </div>

    <hr />

    <h1 class="font-20px">Portal Login Info</h1>

    <div class="set-grid set-grid-2 mt-30">
      <app-roam-input formControlName="email" name="Primary Email" required />
      <app-roam-input formControlName="phone" name="Mobile Phonne" required />
    </div>

    <mat-divider class="mt-40"></mat-divider>

    <section class="mt-20">
      <div class="table-heading">
        <p class="title" style="font-weight: normal; font-size: 20px">
          List of Customer Accounts
        </p>
      </div>
      <app-customer-accounts-table-alt
        [showPagination]="false"
        (removeRow)="removeRow($event)"
        [columnDefs]="accountColDefs()"
        [dataSource]="accounts()" />
      <app-search-customer-accounts-dropdown-button
        class="mt-20"
        (selectionsSubmit)="addRows($event)" />
    </section>
  </form>
</app-container-dialog-set>
