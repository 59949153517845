<div>
  <app-global-form-tabs [tabs]="tabs" [(tabSelected)]="tab" />

  <hr class="mb-2rem" />

  <ng-container [ngSwitch]="tab">
    <app-global-form-association-management-rates
      *ngSwitchCase="'rates'"
      [associationId]="associationDetail?.id"
      [associationManagement]="associationManagement"
    />

    <app-global-form-association-management-team
      *ngSwitchCase="'team'"
      [associationId]="associationDetail?.id"
      [associationManagerId]="associationDetail?.managerId"
    />
  </ng-container>
</div>
