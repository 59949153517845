import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";

export const associationDialog = {
  window95: {
    panelClass: "default-mdc",
    width: "95vw",
    maxWidth: "1000px",
    maxHeight: "95vh",
  },
};

export const configmDelete: IConfirmationDialog = {
  title: "Confirm Delete",
  body: `
    <div class="fw-400 pt-8">Once you delete it, you can’t get it back.</div>`,
  btnCancel: "Cancel",
  btnSubmit: "Delete",
};

export const askingCancel: IConfirmationDialog = {
  title: "Are You Sure?",
  body: `
    <div class="fw-400 pt-8">Data still not been saved, do you want to save?</div>`,
  btnCancel: "No",
  btnSubmit: "Yes",
};

export const configmDeletePhoto: IConfirmationDialog = {
  title: "Delete This Photo",
  body: `
      <div class="fw-400 pt-8">Once you delete it, you can’t get it back.</div>`,
  btnCancel: "Cancel",
  btnSubmit: "Delete",
};
