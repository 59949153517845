<div>
  <app-global-form-tabs [tabs]="tabs" [(tabSelected)]="tab" />

  <hr />

  <ng-container [ngSwitch]="tab">
    <app-global-form-association-board-motions *ngSwitchCase="'motions'" />
    <app-global-form-association-board-reports *ngSwitchCase="'reports'" />
  </ng-container>
</div>
