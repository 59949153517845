<div>
  <button
    cdkOverlayOrigin
    (click)="toggle()"
    class="btn btn-o-primary"
    #openProperty="cdkOverlayOrigin">
    <img [src]="icon.plusRed.src" [alt]="icon.plusRed.alt" />
    <span class="ml-8">{{ buttonLabel() }}</span>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPush="true"
  cdkConnectedOverlayHasBackdrop
  [cdkConnectedOverlayViewportMargin]="7"
  [cdkConnectedOverlayWidth]="420"
  [(cdkConnectedOverlayOpen)]="opened"
  [cdkConnectedOverlayOrigin]="openProperty"
  [cdkConnectedOverlayPositions]="dropdownPosition"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="close()">
  <div class="dropdown pl-16 pr-16 pt-8 pb-0">
    <div class="pt-8 pb-8">
      <mat-form-field
        class="roam-mdc-form-field search-form-field"
        appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          [formControl]="searchForm"
          placeholder="Search"
          type="text" />
      </mat-form-field>
    </div>

    @if (isLoading()) {
      <mat-spinner class="spinner-style" />
    } @else {
      @if (!isEmpty()) {
        <div class="fw-600 pt-10 pb-10">
          ({{ selectionIds.selected.length }}) selected
        </div>
        <div class="gutter scroll-container">
          <div class="reverse hovered">
            @for (item of items(); track $index) {
              <mat-checkbox
                [checked]="selectionIds.isSelected(item.id)"
                (change)="selectionIds.toggle(item.id)">
                <div class="list-item">
                  <div class="detail-item">
                    <span class="title">{{ item.name || "Unknown" }}</span>
                  </div>
                </div>
              </mat-checkbox>
            }
          </div>
        </div>
      } @else {
        <div class="pt-10 pb-10 roam-text-center">
          <p class="fw-600">No items available.</p>
          <p>
            <small>Type to search!</small>
          </p>
        </div>
      }
    }

    <div class="gutter action-button">
      <span (click)="close()" class="text-btn-underline fw-500">Cancel</span>
      <app-roam-button label="Save" (click)="onSubmit()" />
    </div>
  </div>
</ng-template>
