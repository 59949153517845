import { Component, Input, signal } from "@angular/core";
import {
  IUnitDetail,
  IUnitOption,
} from '@app/pages/units/shared/interface';
import { AttachmentModel } from '@app/pages/task/+data-access';

@Component({
  selector: "app-global-form-unit",
  templateUrl: "./global-form-unit.component.html",
})
export class GlobalFormUnitComponent {
  @Input("unitDetail")
  public set setUnitDetail(unitDetail: IUnitDetail) {
    if (!unitDetail) return;

    this.unitDetail = unitDetail;

    this.generateUnitDetail(unitDetail.options);
  }

  @Input()
  public menu!: string;

  @Input()
  public tab?: string;
  
  modelConfig = {
    propertyId: "123abc",
    modelId: crypto.randomUUID(),
    model: "task",
  };

  readonly attachments = signal<AttachmentModel[]>([]);

  public unitAccounting?: any;
  public unitAmenities?: any;
  public unitDetail?: IUnitDetail;
  public unitManagement?: any;

  private generateUnitDetail(details: IUnitOption[]) {
    if (!details) return;

    this.unitAccounting = details
      .find((options) => options.key === "accounting")
      ?.values.reduce((result: any, each) => {
        result[each?.key] = each.value;

        return result;
      }, {});

    this.unitAmenities = details
      .find((options) => options.key === "services")
      ?.values.reduce((result: any, each) => {
        result[each?.key] = each.value;

        return result;
      }, {});

    this.unitManagement = details
      .find((options) => options.key === "management")
      ?.values.reduce((result: any, each) => {
        result[each?.key] = each.value;

        return result;
      }, {});
  }
}
