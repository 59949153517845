<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Dependents</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <div
      class="mb-24"
      *ngFor="let dependent of [1, 2]; let dependent_i = index"
    >
      <ng-container>
        <app-roam-form-info
          [title]="'Dependent Information'"
          [isDelete]="dependent_i !== 0"
          [bg]="'dark'"
        >
          <ng-container content>
            <div class="set-grid set-grid-3">
              <app-roam-input [placeholder]="'Full Name'"></app-roam-input>
              <app-roam-datepicker
                [name]="'Date of Birth'"
              ></app-roam-datepicker>
              <app-roam-input [placeholder]="'Date of Birth'"></app-roam-input>
            </div>
          </ng-container>
        </app-roam-form-info>
      </ng-container>
    </div>

    <div class="mt-24">
      <app-button-add [label]="'Add Another'" [round]="true"></app-button-add>
    </div>
  </ng-container>
</app-container-dialog-set>
