import { Injectable, signal } from "@angular/core";

@Injectable()
export class GlobalFormDialogService {
  #menuName = signal<null | string>(null);
  readonly menuName = this.#menuName.asReadonly();

  setMenuName(name: string): void {
    this.#menuName.set(name);
  }
}
