import { Component, Input } from "@angular/core";
import { IAssociationDetail } from "@app/pages/associations/shared/interface";

@Component({
  selector: "app-global-form-association-board",
  templateUrl: "./global-form-association-board.component.html",
})
export class GlobalFormAssociationBoardComponent {
  @Input()
  public associationDetail?: IAssociationDetail;

  @Input("tab")
  public set setTab(tab: string | undefined) {
    this.tab = tab ?? this.tabs[0];
  }

  public tab: string;
  public tabs: string[] = ["motions", "reports"];

  constructor() {
    this.tab = this.tabs[0];
  }
}
