import { Component, Input, signal } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { iconLib } from "@app/core/const/roam-icon";
import { IAssociationAddress } from "@app/pages/associations/shared/interface";
import { AssociationService } from "@app/shared/services/association.service";
import { Subject, Subscription, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ROAM_TOASTR } from "@app/core/const/app-roam.const";
import { TOASTR_MESSAGE_GENERAL_ADDRESS } from "../../../const";

@Component({
  selector: "app-global-form-association-general-addresses",
  templateUrl: "./global-form-association-general-addresses.component.html",
})
export class GlobalFormAssociationGeneralAddressesComponent {
  @Input()
  public associationId?: string;

  @Input("associationAddresses")
  public set setAssociationAddresses(
    associationAddresses: IAssociationAddress[] | undefined,
  ) {
    if (associationAddresses) this.patchFormAddresses(associationAddresses);
  }

  private destroy$ = new Subject<void>();
  public form: FormGroup;
  public icon = iconLib;
  public isEditLabel: boolean = false;
  public isLoading = signal<boolean>(false);
  private toastrText = TOASTR_MESSAGE_GENERAL_ADDRESS;

  get formAddresses(): FormArray {
    return this.form.get("addresses") as FormArray;
  }

  createFormAddress(addressNumber: number = 1): FormGroup {
    return this.formBuilder.group({
      id: [""],
      name: [`Address ${addressNumber}`],
      state: [""],
      address: [""],
      address2: [""],
      zip: [""],
      city: [""],
      country: [""],
      block: [""],
      notes: [""],
      status: [0],
    });
  }

  patchFormAddresses(associationAddresses: IAssociationAddress[]): void {
    this.formAddresses.clear();
    associationAddresses.forEach((addressObject) => {
      const addressGroup = this.createFormAddress();
      addressGroup.patchValue(addressObject);
      this.formAddresses.push(addressGroup);
    });
  }

  addFormAddress(): void {
    const number = this.formAddresses.length;

    this.formAddresses.push(this.createFormAddress(number));
  }

  editLabel(): void {
    this.isEditLabel = !this.isEditLabel;
  }

  submit(): void {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association General Addresses Edit");
      this.associationService
        .editAssociationsAddress(this.associationId, formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (val) => {
            this.isLoading.set(false);
            this.toastrService.success(
              this.toastrText.SUCCESS_EDIT_GENERAL_ADDRESS,
              "",
              {
                ...ROAM_TOASTR.center,
              },
            );
          },
          error: (val) => {
            this.isLoading.set(false);
            this.toastrService.success(
              this.toastrText.FAILED_EDIT_GENERAL_ADDRESS,
              "",
              {
                ...ROAM_TOASTR.center,
              },
            );
          },
        });
    } else {
      console.log("Association General Addresses Add");
      this.associationService
        .saveAssociationGeneralInfo(formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (val) => {
            this.isLoading.set(false);
            this.toastrService.success(
              this.toastrText.SUCCESS_ADD_GENERAL_ADDRESS,
              "",
              {
                ...ROAM_TOASTR.center,
              },
            );
          },
          error: () => {
            this.isLoading.set(false);
            this.toastrService.error(
              this.toastrText.FAILED_ADD_GENERAL_ADDRESS,
              "",
              {
                ...ROAM_TOASTR.center,
              },
            );
          },
        });
    }

    console.log("DATA", formData);
  }

  close(): void {}

  constructor(
    private associationService: AssociationService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
  ) {
    this.form = this.formBuilder.group({
      addresses: this.formBuilder.array([this.createFormAddress()]),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
