import { Component, Input } from "@angular/core";
import {
  IAssociationDetail,
  IAssociationOption,
} from "../../../../../../pages/associations/shared/interface/association-detail.interface";

@Component({
  selector: "app-global-form-association",
  templateUrl: "./global-form-association.component.html",
})
export class GlobalFormAssociationComponent {
  @Input("associationDetail")
  public set setAssociationDetail(associationDetail: IAssociationDetail) {
    if (!associationDetail) return;

    this.associationDetail = associationDetail;

    this.generateAssociationDetail(associationDetail.options);
  }

  @Input()
  public menu!: string;

  @Input()
  public tab?: string;

  public associationAccounting?: any;
  public associationAmenities?: any;
  public associationDetail?: IAssociationDetail;
  public associationManagement?: any;

  private generateAssociationDetail(details: IAssociationOption[]) {
    if (!details) return;

    this.associationAccounting = details
      .find((options) => options.key === "accounting")
      ?.values.reduce((result: any, each) => {
        result[each?.key] = each.value;

        return result;
      }, {});

    this.associationAmenities = details
      .find((options) => options.key === "services")
      ?.values.reduce((result: any, each) => {
        result[each?.key] = each.value;

        return result;
      }, {});

    this.associationManagement = details
      .find((options) => options.key === "management")
      ?.values.reduce((result: any, each) => {
        result[each?.key] = each.value;

        return result;
      }, {});
  }
}
