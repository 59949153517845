<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="true"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container>
    <label class="fw-500">Reports</label>
    <div>
      <mat-checkbox
        >Allow board members to run the following reports</mat-checkbox
      >
      <section class="set-grid set-grid-2">
        <!-- left menu -->
        <div class="left-div-checkbox">
          <!-- financial checkbox -->
          <div class="display-checkbox">
            <span>
              <mat-checkbox
                [checked]="allCompleteFinancial"
                [indeterminate]="someComplete('financial')"
                (change)="setAll($event.checked, 'financial')"
              >
                {{ ALL_CHECK["financial"].name }}
              </mat-checkbox>
            </span>
            <span>
              <ul class="pl-30">
                @for (subtask of ALL_CHECK['financial'].subtasks; track subtask)
                {
                <li>
                  <mat-checkbox
                    [(ngModel)]="subtask.completed"
                    (ngModelChange)="updateAllComplete('financial')"
                  >
                    {{ subtask.name }}
                  </mat-checkbox>
                </li>
                }
              </ul>
            </span>
          </div>
          <!-- property report -->
          <div class="display-checkbox">
            <span>
              <mat-checkbox
                [checked]="allCompleteProperty"
                [indeterminate]="someComplete('property')"
                (change)="setAll($event.checked, 'property')"
              >
                {{ ALL_CHECK["property"].name }}
              </mat-checkbox>
            </span>
            <span>
              <ul class="pl-30">
                @for (subtask of ALL_CHECK['property'].subtasks; track subtask)
                {
                <li>
                  <mat-checkbox
                    [(ngModel)]="subtask.completed"
                    (ngModelChange)="updateAllComplete('property')"
                  >
                    {{ subtask.name }}
                  </mat-checkbox>
                </li>
                }
              </ul>
            </span>
          </div>
          <!-- transaction report -->
          <div class="display-checkbox">
            <span>
              <mat-checkbox
                [checked]="allCompleteTransaction"
                [indeterminate]="someComplete('transaction')"
                (change)="setAll($event.checked, 'transaction')"
              >
                {{ ALL_CHECK["transaction"].name }}
              </mat-checkbox>
            </span>
            <span>
              <ul class="pl-30">
                @for (subtask of ALL_CHECK['transaction'].subtasks; track
                subtask) {
                <li>
                  <mat-checkbox
                    [(ngModel)]="subtask.completed"
                    (ngModelChange)="updateAllComplete('transaction')"
                  >
                    {{ subtask.name }}
                  </mat-checkbox>
                </li>
                }
              </ul>
            </span>
          </div>
        </div>
        <div class="right-div-checkbox">
          <!-- associaiton report -->
          <div class="display-checkbox">
            <span>
              <mat-checkbox
                [checked]="allCompleteAssociations"
                [indeterminate]="someComplete('association')"
                (change)="setAll($event.checked, 'association')"
              >
                {{ ALL_CHECK["association"].name }}
              </mat-checkbox>
            </span>
            <span>
              <ul class="pl-30">
                @for (subtask of ALL_CHECK['association'].subtasks; track
                subtask) {
                <li>
                  <mat-checkbox
                    [(ngModel)]="subtask.completed"
                    (ngModelChange)="updateAllComplete('association')"
                  >
                    {{ subtask.name }}
                  </mat-checkbox>
                </li>
                }
              </ul>
            </span>
          </div>
          <!-- task report -->
          <div class="display-checkbox">
            <span>
              <mat-checkbox
                [checked]="allCompleteTask"
                [indeterminate]="someComplete('task')"
                (change)="setAll($event.checked, 'task')"
              >
                {{ ALL_CHECK["task"].name }}
              </mat-checkbox>
            </span>
            <span>
              <ul class="pl-30">
                @for (subtask of ALL_CHECK['task'].subtasks; track subtask) {
                <li>
                  <mat-checkbox
                    [(ngModel)]="subtask.completed"
                    (ngModelChange)="updateAllComplete('task')"
                  >
                    {{ subtask.name }}
                  </mat-checkbox>
                </li>
                }
              </ul>
            </span>
          </div>
        </div>
      </section>
    </div>
  </ng-container>
</app-container-dialog-set>
