<app-roam-layout-container-menu [title]="(data ? '': 'Add ') + moduleLabel">
  <ng-container menu>
    <app-roam-sidebar-menu
      [(menu)]="menu"
      [menus]="menus"
      [main]="false"
      (menuChange)="onMenuChange($event)"
    />
  </ng-container>

  <ng-container content>
    <ng-container [ngSwitch]="module">
      <app-global-form-association
              *ngSwitchCase="'association'"
              [associationDetail]="data"
              [menu]="menu"
              [tab]="tab"
      />

      <app-global-form-unit
              *ngSwitchCase="'unit'"
              [unitDetail]="data"
              [menu]="menu"
              [tab]="tab"
      />

      <app-global-form-contact-customer
        *ngSwitchCase="'customer'"
        [contactDetail]="data"
        [menu]="menu"
      />

      <app-global-form-contact-employee
        *ngSwitchCase="'employee'"
        [contactDetail]="data"
        [menu]="menu"
      />

      <app-global-form-contact-owner
        *ngSwitchCase="'owner'"
        [contactDetail]="data"
        [menu]="menu"
      />

      <app-global-form-contact-tenant
        *ngSwitchCase="'tenant'"
        [contactDetail]="data"
        [menu]="menu"
      />

      <app-global-form-contact-vendor
        *ngSwitchCase="'vendor'"
        [contactDetail]="data"
        [menu]="menu"
      />
    </ng-container>
  </ng-container>
</app-roam-layout-container-menu>
