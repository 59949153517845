import { ROAM_IMAGE_SVG } from "@app/core/const/app-roam.const";

export class ExpandAccordion {
  public isExpand: boolean = false;

  public icon = {
    open: `${ROAM_IMAGE_SVG}/ic-expand.svg`,
    close: `${ROAM_IMAGE_SVG}/ic-collapse.svg`
  }

  public text = {
    open: `Open All`,
    close: `Collapse All`
  }
}
