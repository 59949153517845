import { Component, Input } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { IUnitDetail } from "@app/pages/units/shared/interface";

@Component({
  selector: "app-global-form-unit-general",
  templateUrl: "./global-form-unit-general.component.html",
})
export class GlobalFormUnitGeneralComponent {
  @Input()
  public unitAmenities?: any;

  @Input()
  public unitDetail?: IUnitDetail;

  @Input("tab")
  public set setTab(tab: string | undefined) {
    this.tab = tab ?? this.tabs[0];
  }

  public tab: string;
  public tabs: string[] = ["information", "addresses"];

  readonly form = this.formBuilder.group({
    street1: [""],
    street2: [""],
    city: [""],
    state: [""],
    country: [""],
    zipCode: [""],
  });

  constructor(readonly formBuilder: FormBuilder) {
    this.tab = this.tabs[0];
  }

  checkForFormDirty() {
    //show dialog if form is dirty before move to another page
  }
}
