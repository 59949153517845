<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <div class="set-grid set-grid-2 pt-10">
      <app-roam-select
        class="text-input"
        formControlName="defaultAdminItemId"
        placeholder="Default admin item"
        [items]="itemOptions ?? []"
      />

      <app-roam-select
        class="text-input"
        formControlName="secondaryAdminItemId"
        placeholder="Secondary admin item"
        [items]="itemOptions ?? []"
      />

      <app-roam-select
        class="text-input"
        formControlName="attorneyItemId"
        placeholder="Attorney item"
        [items]="itemOptions ?? []"
      />

      <app-roam-select
        class="text-input"
        formControlName="interestItemId"
        placeholder="Interest item"
        [items]="itemOptions ?? []"
      />

      <app-roam-input
        class="inputs"
        formControlName="interestPercent"
        name="Interest rate %"
      />

      <app-roam-select
        class="text-input"
        formControlName="lateFeeItemId"
        placeholder="Late fee item"
        [items]="itemOptions ?? []"
      />

      <app-roam-select
        class="text-input"
        formControlName="violationFineItemId"
        placeholder="Return Check Fee Item"
        [items]="itemOptions ?? []"
      />

      <app-roam-select
        class="text-input"
        formControlName="violationFineItemId"
        placeholder="Violation fine item"
        [items]="itemOptions ?? []"
      />

      <app-roam-input
        class="inputs"
        formControlName="violationFineAmount"
        name="Amount"
      />

      <app-roam-input
        class="inputs"
        formControlName="violationFinePercent"
        name="Percent"
      />
    </div>

    <mat-radio-group
      class="d-flex gap-10 align-center mt-20"
      formControlName="lessGreater"
    >
      <span>Whichever is</span>

      <mat-radio-button value="less">Less</mat-radio-button>

      <span>or</span>

      <mat-radio-button value="greater">Greater</mat-radio-button>
    </mat-radio-group>
  </ng-container>
</app-container-dialog-set>
