import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AccountingService } from "@app/shared/services/accounting.service";

@Component({
  selector: "app-global-form-association-accounting-options",
  templateUrl: "./global-form-association-accounting-options.component.html",
})
export class GlobalFormAssociationAccountingOptionsComponent {
  @Input("associationAccounting")
  public set setAssociationAccounting(associationAccounting: any | undefined) {
    if (associationAccounting) this.patchForm(associationAccounting);
  }

  @Input()
  public associationId?: string;

  @Input()
  public paymentMethodOptions?: any[];

  @Input()
  public paymentTermOptions?: any[];

  public form: FormGroup;

  constructor(
    private accountingService: AccountingService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.createForm();
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      bankAddress: [""],
      bankName: [""],
      bankCode: [""],
      bankCsz: [""],
      depositAccountNumber: [""],
      depositRoutingNumber: [""],
      paymentMethodId: [""],
      paymentTermsId: [""],
    });
  }

  private patchForm(associationAccounting: any) {
    this.form.patchValue({ ...associationAccounting });
  }

  public submit() {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association Accounting Options Edit");

      this.accountingService
        .saveAssociationAccounting(this.associationId, formData)
        .subscribe();
    } else {
      console.log("Association Accounting Options Add");
    }

    console.log("DATA", formData);
  }
}
