import { Component } from "@angular/core";

@Component({
  selector: "app-global-form-contact-owner-document",
  templateUrl: "./global-form-contact-owner-document.component.html",
})
export class GlobalFormContactOwnerDocumentComponent {
  public tabChips: string[] = [
    "General",
    "Collection Letters",
    "Legal Letters",
  ];
  public selectedChip: string = "General";

  public submit() {}
}
