import { Component, Input, inject, signal } from "@angular/core";
import { PropertyStore } from "./data-access/property.store";
import { IVendor } from "@app/shared/interfaces/vendor.interface";

@Component({
  selector: "app-global-form-contact-form-property",
  templateUrl: "./global-form-contact-form-property.component.html",
  styleUrls: ["./global-form-contact-form-property.component.scss"],
})
export class GlobalFormContactVendorPropertyComponent {
  @Input()
  set dataVendor(data: IVendor) {
    this.store.resetState();
    data.linkedProperties?.forEach((el) => {
      this.store.addOne(el);
    });
  }

  store = inject(PropertyStore);
  isLoading = signal<boolean>(false);
  propertyStatus: boolean = false;

  submit(): void {}
  close(): void {}

  constructor() {}
}
