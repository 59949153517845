import { Component } from "@angular/core";

@Component({
  selector: "app-global-form-contact-form-taxes",
  templateUrl: "./global-form-contact-form-taxes.component.html",
})
export class GlobalFormContactVendorTaxesComponent {
  public sendVendor: boolean = false;
  public sameVendorAddr: boolean = false;

  public federalTaxOptions = [
    {
      id: 0,
      text: "Do not send 1099 forms to this vendor",
      value: false,
    },
    {
      id: 1,
      text: "I want to send 1099 forms to this vendor",
      value: true,
    },
  ];

  public submit() {}
}
