import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AssociationService } from "@app/shared/services/association.service";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-global-form-association-general-documents",
  templateUrl: "./global-form-association-general-documents.component.html",
})
export class GlobalFormAssociationGeneralDocumentsComponent {
  @Input()
  public associationId?: string;

  @Input("associationDocuments")
  public set setAssociationDocuments(associationDocuments: any | undefined) {
    if (associationDocuments) this.generateForm(associationDocuments);
  }

  private destroy$ = new Subject<void>();
  public form: FormGroup;
  public files: any[] = [
    {
      name: "File Name.pdf",
      type: "pdf",
      date: "April 30, 2023",
      user: "Rowdy",
      size: 1800,
      file: {},
    },
    {
      name: "File Name 2.pdf",
      type: "pdf",
      date: "April 30, 2023",
      user: "Rowdy",
      size: 12000,
      file: {},
    },
  ];

  generateForm(associationDocuments: any) {
    for (let key in associationDocuments) {
      this.form.addControl(key, new FormControl(associationDocuments[key]));
    }
  }

  submit(): void {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association General Documents Edit");

      const transformedData = {
        values: Object.entries(formData).map(([key, value]) => ({
          key,
          value,
        })),
      };

      this.associationService
        .saveAssociationServices(this.associationId, transformedData)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    } else {
      console.log("Association General Documents Add");
    }

    console.log("DATA", formData);
  }

  close(): void {}

  uploadFile(file: File): void {
    const { name, size, lastModified } = file;

    const newFile = {
      name: name,
      size: size,
      date: new Date(lastModified),
      user: "User",
      file: file,
    };
    this.files.push(newFile);
    this.form.controls["files"].setValue(this.files);
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.form.controls["files"].setValue(this.files);
  }

  constructor(
    private associationService: AssociationService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      files: new FormArray([]),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
