<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Send Payments</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <app-roam-toggle-slider
      [label]="'This vendor can work on all my properties'"
      [name]="'vendor'"
      (OnChangeToggle)="checked = !checked"
    ></app-roam-toggle-slider>

    <div *ngIf="checked">
      <div class="mt-24">
        <app-roam-radio-card
          [options]="emailOptions"
          [(ngModel)]="emailType"
        ></app-roam-radio-card>
      </div>

      <hr class="mtb-24" />

      <h5 class="font-18px fw-500 mb-20">Email Settings</h5>

      <ng-container
        *ngTemplateOutlet="
          emailType === 'recommend' ? emailRecommendation : emailCheck
        "
      ></ng-container>
    </div>
  </ng-container>
</app-container-dialog-set>

<ng-template #emailRecommendation>
  <div>
    <app-roam-input [name]="'Billing Email'"></app-roam-input>
  </div>
  <div class="mt-24">
    <app-roam-toggle-slider
      [label]="'Ask the payee for a PIN before accepting this payment'"
      [name]="'payee'"
      [labelClass]="'fw-500'"
    ></app-roam-toggle-slider>
  </div>
</ng-template>

<ng-template #emailCheck>
  <div class="set-grid set-grid-2 gap-24">
    <app-roam-input [name]="'Street 1'"></app-roam-input>
    <app-roam-input [name]="'Street 2'"></app-roam-input>
    <app-roam-input [name]="'City'"></app-roam-input>
    <app-roam-input [name]="'State'"></app-roam-input>
    <app-roam-input [name]="'Country'"></app-roam-input>
    <app-roam-input [name]="'Zip Code'"></app-roam-input>
  </div>
</ng-template>
