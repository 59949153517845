export const ContactAgentTableKeyConst = [
  "name",
  "groups.0.name",
  "contact",
  "selection",
];

export const ContactAgentTableLabelConst = ["Name", "Company", "Contact", ""];

export const ContactAgentTableSortKeyConst = []; // ["name"];

export const ContactCustomerTableKeyConst = [
  "name",
  "units.0.address",
  // "address",
  "accountNumber",
  "generation",
  "totalBalance",
  "portalStatus", // TODO fix this dummy
  "selection",
];

export const ContactCustomerTableLabelConst = [
  "Name",
  "Address",
  "Account",
  "Generation",
  "Account Balance",
  "Portal",
  "",
];

export const ContactCustomerTableSortKeyConst = [];
// [
//   "name",
//   null,
//   "accountNumber",
//   "generation",
//   "totalBalance",
// ];

export const ContactDeveloperTableKeyConst = [
  "name",
  "groups.0.name",
  "contact",
  "selection",
];

export const ContactDeveloperTableLabelConst = [
  "Name",
  "Company",
  "Contact",
  "",
];

export const ContactDeveloperTableSortKeyConst = []; // ["name"];

export const ContactEmployeeTableKeyConst = [
  "name",
  "contact",
  "groupRoleName",
  "countProperties",
  "portalStatus",
  "selection",
];

export const ContactEmployeeTableLabelConst = [
  "Name",
  "Contact",
  "Role",
  "# Of Properties",
  "Portal",
  "",
];

export const ContactEmployeeTableSortKeyConst = [];
// [
//   "name",
//   "contactEmail",
//   "roleName",
// ];

export const ContactManagerTableKeyConst = ["name", "unitCount", "selection"];

export const ContactManagerTableLabelConst = ["Name", "Units Managed", ""];

export const ContactManagerTableSortKeyConst = []; // ["name"];

export const ContactOwnerTableKeyConst = [
  "name",
  // "association", // TODO fix this dummy
  "customers.0.units.0.address",
  // "address",
  "contact",
  "portalStatus", // TODO fix this dummy
  "selection",
];

export const ContactOwnerTableLabelConst = [
  "Name",
  // "Association",
  "Address",
  "Contact",
  "Portal",
  "",
];

export const ContactOwnerTableSortKeyConst = []; // ["name", null, null, "contact"];

export const ContactTenantTableKeyConst = [
  "name",
  "customers.0.units.0.address",
  // "address",
  "contact",
  "portalStatus", // TODO fix this dummy
  "selection",
];

export const ContactTenantTableLabelConst = [
  "Name",
  "Address",
  "Contact",
  "Portal",
  "",
];

export const ContactTenantTableSortKeyConst = []; // ["name", "contact"];

export const ContactVendorTableKeyConst = [
  "name",
  "contact",
  "type",
  "countUsers",
  "countProperties",
  "totalBalance",
  "selection",
];

export const ContactVendorTableLabelConst = [
  "Name",
  "Contact",
  "Vendor Type",
  "# Of Users",
  "# Of Properties",
  "Balance",
  "",
];

export const ContactVendorTableSortKeyConst = []; // ["name"];

//for property menu

export const ContactVendorTablePropertyConst = ["units", "city", "action"];

export const ContactVendorTablePropertyLabelConst = [
  "Units",
  "City,State",
  "Action",
];

export const ContactVendorTablePropertySortKeyConst = ["units"];
