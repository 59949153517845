import { Component, Input } from "@angular/core";
import { IAssociationDetail } from '@app/pages/associations/shared/interface';
import { AssociationService } from '@app/shared/services/association.service';

@Component({
  selector: "app-global-form-association-accounting",
  templateUrl: "./global-form-association-accounting.component.html",
})
export class GlobalFormAssociationAccountingComponent {
  @Input()
  public associationAccounting?: any;

  @Input("associationDetail")
  public set setAssociationDetail(
    associationDetail: IAssociationDetail | undefined,
  ) {
    if (!associationDetail) return;

    this.associationDetail = associationDetail;

    this.getAccountingOptions(associationDetail.id);
  }

  @Input("tab")
  public set setTab(tab: string | undefined) {
    this.tab = tab ?? this.tabs[0];
  }

  public associationDetail?: IAssociationDetail;
  public tab: string;
  public tabs: string[] = [
    "options",
    "accounts",
    "assessments",
    "collections",
    "dues",
    "reports",
  ];

  public accountingOptions: any = {
    accounts: [],
    items: [],
    methods: [],
    terms: [],
  };

  constructor(private associationService: AssociationService) {
    this.tab = this.tabs[0];
  }

  private getAccountingOptions(associationDetailId: string) {
    this.associationService
      .getAccountsItemsMethodsTerms(associationDetailId)
      .subscribe((response: any) => {
        this.accountingOptions = response;
      });
  }
}
