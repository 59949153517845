import {
  Component,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { UserStore } from "@app/pages/users/+data-access";
import { ColDefUser } from "@app/pages/users/+features";
import { dedupItems, RoamTableRowChangeEvent } from "@app/utils";
import { GlobalFormDialogService } from "../../../container/global-form-dialog/global-form-dialog.service";

type FormType = FormGroup<{
  users: FormControl<{ id: string; roleSlug: string }[]>;
}>;

@Component({
  selector: "app-global-form-contact-form-resident",
  styleUrl: "./global-form-contact-form-resident.component.scss",
  templateUrl: "./global-form-contact-form-resident.component.html",
  encapsulation: ViewEncapsulation.None,
  providers: [UserStore],
})
export class GlobalFormContactFormResidentComponent {
  #dialogService = inject(GlobalFormDialogService);
  protected userConfig = inject(UserConfigStore);
  protected associationId: string | null = null;
  protected usersColDefs = signal<ColDefUser[]>([
    "name",
    "email",
    "phoneNumber",
    "contactTypeSelection",
    "removeRow",
  ]);

  readonly users = model<any[]>([]);
  readonly form = input.required<FormType>();
  readonly formSubmit = output<void>();

  removeUser(id: string): void {
    this.users.update(list => {
      return list.filter(x => x.id !== id);
    });
  }

  addUsers(users: any[]): void {
    this.users.update(list => {
      return dedupItems([...list, ...users], (a, b) => a.id === b.id);
    });
  }

  onRowChange({ rowData: row }: RoamTableRowChangeEvent): void {
    const formControl = this.form().controls.users;
    const updates = formControl.value.map(v => {
      if (row.id === v.id) {
        v = { ...v, roleSlug: row.roleName };
      }
      return v;
    });
    formControl.patchValue(updates);
  }

  protected updateForm = effect(
    () => {
      const users = this.users();
      const payload = users.map(x => {
        return { id: x.id, roleSlug: x.roleName || "Owner" };
      });
      this.form().controls.users.patchValue(payload);
    },
    { allowSignalWrites: true }
  );

  protected dialogMenuChanges = effect(() => {
    const menuName = this.#dialogService.menuName();
    if (menuName) {
      console.log("menu changed to ", menuName);
    }
  });
}
