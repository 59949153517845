<div class="property-table">
  <app-table
    [isLoading]="isLoading()"
    [tableConfig]="tableConfig"
    [emptyState]="emptyState"
    (mouseOverChange)="onMouseOverChange($event)"
    (pageChange)="onPageChange($event)"
    (sortChange)="onSortChange($event)"
  >
    <ng-template #customTemplate let-key="key" let-data="data">
      <ng-container *ngIf="key === 'units'">
        <div class="text-units">
          <img [src]="data.imageUrl" alt="" />
          {{ data.name }}
        </div>
      </ng-container>

      <ng-container *ngIf="key === 'city'">
        <div>{{ data.city }}, {{ data.state }}</div>
      </ng-container>

      <ng-container *ngIf="key === 'action'">
        <button
          mat-icon-button
          class="trash-icon"
          (click)="removeProperty(data)"
        >
          <span inlineSVG="/assets/svg/trash-red-icon.svg"></span>
        </button>
      </ng-container>
    </ng-template>
  </app-table>
</div>
