<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <div class="set-grid set-grid-2 pt-10">
      <app-roam-select
        formControlName="accountsPayableAccountId"
        placeholder="Accounts Payable"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="accountsReceivableAccountId"
        placeholder="Accounts Receivable"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="bankAccountId"
        placeholder="Bank Account"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="checkingAccountId"
        placeholder="Checking Account"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="depositAccountId"
        placeholder="Deposit Account"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="retainedEarningsAccountId"
        placeholder="Retained Earnings Account"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="discrepancyReconciliationAccountId"
        placeholder="Discrepancy Reconciliation Account"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="interestAccountId"
        placeholder="Interest Account"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="bankFeeAccountId"
        placeholder="Bank Fee Account"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="priorYearCollectedAccountId"
        placeholder="Prior Year Collected Account"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="badDebtExpenseAccountId"
        placeholder="Bad Debt Expense Account"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="uncollectedDuesAccountId"
        placeholder="Uncollected Dues Account"
        [items]="accountOptions ?? []"
      />

      <app-roam-select
        formControlName="uncollectedOtherAccountId"
        placeholder="Uncollected Other Account"
        [items]="accountOptions ?? []"
      />
    </div>
  </ng-container>
</app-container-dialog-set>
