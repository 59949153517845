<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <div class="card-group" formArrayName="phones">
      <label>Phone</label>

      <div
        class="form-group-Email-Phone mt-20"
        *ngFor="let phone of formPhones.controls; let formIndex = index"
        [formGroupName]="formIndex"
      >
        <app-roam-input formControlName="name" name="Phone Name" />

        <app-roam-input formControlName="number" name="Phone" />
        <button
          *ngIf="formIndex !== 0"
          mat-icon-button
          class="trash-icon"
          (click)="removePhone(formIndex)"
        >
          <span inlineSVG="/assets/svg/trash-red-icon.svg"></span>
        </button>
      </div>

      <div class="mt-16">
        <label
          class="text-underline tc-primary c-pointer"
          (click)="addFormPhone()"
        >
          <img class="mr-8" [src]="icon.plusRed.src" [alt]="icon.plusRed.alt" />
          Add
        </label>
      </div>
    </div>

    <div class="card-group" formArrayName="emails">
      <label>Email</label>

      <div
        class="form-group-Email-Phone mt-20"
        *ngFor="let email of formEmails.controls; let formIndex = index"
        [formGroupName]="formIndex"
      >
        <app-roam-input formControlName="name" name="Email Name" />

        <app-roam-input formControlName="email" name="Email" />
        <button
          *ngIf="formIndex !== 0"
          mat-icon-button
          class="trash-icon"
          (click)="removeEmail(formIndex)"
        >
          <span inlineSVG="/assets/svg/trash-red-icon.svg"></span>
        </button>
      </div>

      <div class="mt-16">
        <label
          class="text-underline tc-primary c-pointer"
          (click)="addFormEmail()"
        >
          <img class="mr-8" [src]="icon.plusRed.src" [alt]="icon.plusRed.alt" />
          Add
        </label>
      </div>
    </div>
  </ng-container>
</app-container-dialog-set>
