import { Component, signal } from "@angular/core";

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: Task[];
}

@Component({
  selector: "app-global-form-association-board-reports",
  templateUrl: "./global-form-association-board-reports.component.html",
  styleUrls: ["./global-form-association-board-reports.component.scss"],
})
export class GlobalFormAssociationBoardReportsComponent {
  public isLoading = signal<boolean>(false);
  public allCompleteFinancial: boolean = false;
  public allCompleteProperty: boolean = false;
  public allCompleteTransaction: boolean = false;
  public allCompleteAssociations: boolean = false;
  public allCompleteTask: boolean = false;

  public ALL_CHECK: any = {
    financial: {
      name: "Financial reports",
      completed: false,
      subtasks: [
        { name: "Accounts receivable summary", completed: false },
        { name: "Balance sheet", completed: false },
        { name: "Balance sheet by fund", completed: false },
        { name: "Budget Vs.  actuals", completed: false },
        { name: "Cash flow statement", completed: false },
        { name: "General Ledger", completed: false },
        { name: "Income Statement", completed: false },
        { name: "Income statement detailed", completed: false },
        { name: "Property statement", completed: false },
        { name: "Trial balance", completed: false },
        { name: "Trial balance consolidated", completed: false },
        { name: "Vendor ledger", completed: false },
      ],
    },
    property: {
      name: "Property reports",
      completed: false,
      subtasks: [
        { name: "Event history", completed: false },
        { name: "Late fee policy", completed: false },
        { name: "Mater  readings", completed: false },
        { name: "Payment reminder policy", completed: false },
      ],
    },
    transaction: {
      name: "Transactions reports",
      completed: false,
      subtasks: [
        { name: "Transaction detail by account", completed: false },
        { name: "Unpaid bills by vendor", completed: false },
      ],
    },
    association: {
      name: "Associations reports",
      completed: false,
      subtasks: [
        { name: "Associations owner notes", completed: false },
        { name: "Association owner statement", completed: false },
        { name: "Association owner vehicles", completed: false },
        { name: "Board members", completed: false },
        { name: "Committee members", completed: false },
        { name: "Current association owners", completed: false },
        { name: "Delinquent association owners", completed: false },
        { name: "Recurring charges", completed: false },
        { name: "Violations", completed: false },
      ],
    },
    task: {
      name: "Task reports",
      completed: false,
      subtasks: [
        { name: "Completes tasks", completed: false },
        { name: "Open tasks", completed: false },
        { name: "Works Order", completed: false },
      ],
    },
  };

  updateAllComplete(checkboxName: string) {
    const result =
      this.ALL_CHECK[checkboxName].subtasks != null &&
      this.ALL_CHECK[checkboxName].subtasks.every((t: Task) => t.completed);
    switch (checkboxName) {
      case "financial": {
        this.allCompleteFinancial = result;
        break;
      }
      case "property": {
        this.allCompleteProperty = result;
        break;
      }
      case "transaction": {
        this.allCompleteTransaction = result;
        break;
      }
      case "association": {
        this.allCompleteAssociations = result;
        break;
      }
      case "task": {
        this.allCompleteTask = result;
        break;
      }
    }
  }

  someComplete(checkboxName: string): boolean {
    let allComplete;
    switch (checkboxName) {
      case "financial": {
        allComplete = this.allCompleteFinancial;
        break;
      }
      case "property": {
        allComplete = this.allCompleteProperty;
        break;
      }
      case "transaction": {
        allComplete = this.allCompleteTransaction;
        break;
      }
      case "association": {
        allComplete = this.allCompleteAssociations;
        break;
      }
      case "task": {
        allComplete = this.allCompleteTask;
        break;
      }
    }

    if (this.ALL_CHECK[checkboxName].subtasks == null) {
      return false;
    }
    return (
      this.ALL_CHECK[checkboxName].subtasks.filter((t: Task) => t.completed)
        .length > 0 && !allComplete
    );
  }

  setAll(completed: boolean, checkboxName: string) {
    switch (checkboxName) {
      case "financial": {
        this.allCompleteFinancial = completed;
        break;
      }
      case "property": {
        this.allCompleteProperty = completed;
        break;
      }
      case "transaction": {
        this.allCompleteTransaction = completed;
        break;
      }
      case "association": {
        this.allCompleteAssociations = completed;
        break;
      }
      case "task": {
        this.allCompleteTask = completed;
        break;
      }
    }
    if (this.ALL_CHECK[checkboxName].subtasks == null) {
      return;
    }
    this.ALL_CHECK[checkboxName].subtasks.forEach(
      (t: Task) => (t.completed = completed),
    );
  }

  close(): void {}
  submit(): void {}
}
