<ng-container [ngSwitch]="menu">
  <app-global-form-contact-form-address *ngSwitchCase="'address'" />

  <app-global-form-contact-form-employee *ngSwitchCase="'employee'" />

  <app-global-form-contact-form-document *ngSwitchCase="'document'" />

  <app-global-form-contact-form-insurance *ngSwitchCase="'insurance'" />

  <app-global-form-contact-form-property
    [dataVendor]="contactDetail"
    *ngSwitchCase="'property'"
  />

  <app-global-form-contact-form-vendor
    *ngSwitchCase="'vendor'"
    [form]="controls.vendor"
    (formSubmit)="onVendorSubmit()"
  />

  <app-global-form-contact-form-service *ngSwitchCase="'service'" />

  <app-global-form-contact-form-taxes *ngSwitchCase="'taxes'" />
</ng-container>
