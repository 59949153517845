import { Component, Input } from "@angular/core";
import { IAssociationDetail } from "@app/pages/associations/shared/interface";

@Component({
  selector: "app-global-form-association-general",
  templateUrl: "./global-form-association-general.component.html",
})
export class GlobalFormAssociationGeneralComponent {
  @Input()
  public associationAmenities?: any;

  @Input()
  public associationDetail?: IAssociationDetail;

  @Input("tab")
  public set setTab(tab: string | undefined) {
    this.tab = tab ?? this.tabs[0];
  }

  public tab: string;
  public tabs: string[] = [
    "information",
    "property types",
    "addresses",
    "photos",
    "contact",
    "amenities",
    "documents",
  ];

  constructor() {
    this.tab = this.tabs[0];
  }

  checkForFormDirty() {
    //show dialog if form is dirty before move to another page
  }
}
