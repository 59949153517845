import { Component } from "@angular/core";
import { iconPet } from "@app/core/const/roam-icon";

interface IPet {
  id: string;
  title: string;
  icon: string;
  focus: boolean;
}

@Component({
  selector: "app-global-form-association-compliance-pets",
  templateUrl: "./global-form-association-compliance-pets.component.html",
  styleUrls: ["./global-form-association-compliance-pets.component.scss"],
})
export class GlobalFormAssociationCompliancePetsComponent {
  public icon = iconPet;
  public PetsCategory: IPet[] = [
    {
      id: "1",
      title: "Pet Allowed",
      icon: this.icon.petAllow.src,
      focus: false,
    },
    {
      id: "2",
      title: "Pet Not Allowed",
      icon: this.icon.petNotAllow.src,
      focus: false,
    },
  ];

  selectPetsCategory(item: IPet): void {
    this.PetsCategory.forEach((mc) => {
      mc.focus = item.id === mc.id;
    });
  }
  submit(): void {}
  close(): void {}
}
