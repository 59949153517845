import { Component } from "@angular/core";
import { iconParking, iconPet } from "@app/core/const/roam-icon";

interface IParking {
  id: string;
  title: string;
  icon: string;
  focus: boolean;
}
@Component({
  selector: "app-global-form-association-compliance-parking",
  templateUrl: "./global-form-association-compliance-parking.component.html",
  styleUrls: ["./global-form-association-compliance-parking.component.scss"],
})
export class GlobalFormAssociationComplianceParkingComponent {
  public icon = iconParking;
  public ParkingCategory: IParking[] = [
    {
      id: "1",
      title: "Parking Allowed",
      icon: this.icon.parkAllow.src,
      focus: false,
    },
    {
      id: "2",
      title: "Parking Not Allowed",
      icon: this.icon.parkNotAllow.src,
      focus: false,
    },
  ];

  selectParkingCategory(item: IParking): void {
    this.ParkingCategory.forEach((mc) => {
      mc.focus = item.id === mc.id;
    });
  }
  submit(): void {}
  close(): void {}
}
