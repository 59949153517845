<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Property</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="true"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container>
    <app-roam-toggle-slider
      [label]="'This vendor can work on all my properties'"
      [name]="'propertyStatus'"
      [(ngModel)]="propertyStatus"
    />

    <div class="mt-24" *ngIf="!propertyStatus">
      <h6 class="font-18px fw-500 mb-8">List of Associated Properties</h6>
      <!-- <div class="chip-list-item">
        @for (property of store.propertiesList(); track property.id) {
        <div mat-flat-button class="chip-item">
          <img
            class="chip-icon"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4CBB3Pw-V7gi9hzWmEITIgtA53Bh4PH54v0Eu8qz2gQ&s"
            alt="property"
          />
          <div class="chip-body">
            <div class="chip-title">{{ property.name }}</div>
            <div class="chip-label">{{ property.desc }}</div>
          </div>

          <mat-icon
            class="chip-close-icon"
            (click)="removeProperty(property)"
            fontSet="material-icons-outlined"
          >
            close
          </mat-icon>
        </div>
        }
      </div> -->
      <contact-select-property-dropdown />
      <vendor-property-table />
    </div>
  </ng-container>
</app-container-dialog-set>
