import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { iconLib } from "@app/core/const/roam-icon";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { InputModule } from "@app/shared/components/input/input.module";

@Component({
  standalone: true,
  selector: "dialog-compliance-violations-category",
  imports: [MatDialogModule, RoamButtonComponent, InputModule],
  template: `<div mat-dialog-title>
      <div class="justify-between">
        <h2 class="fw-500">Add New Category</h2>
        <div class="c-pointer" mat-dialog-close>
          <img [src]="icon.closeBlack.src" [alt]="icon.closeBlack.alt" />
        </div>
      </div>
    </div>
    <div class="mat-dialog-roam-content">
      <app-roam-input name="Category Name" />
    </div>
    <mat-dialog-actions
      class="mat-dialog-roam-action gap-10"
      [style.justify-content]="'space-between !important'"
    >
      <button mat-button mat-dialog-close class="btn-close c-pointer">
        <span class="text-underline"> Cancel </span>
      </button>
      <app-roam-button
        [class]="'btn btn-primary bold pr-24 pl-24 h-45'"
        [label]="'Save'"
        (onClick)="submit()"
      >
      </app-roam-button>
    </mat-dialog-actions> `,
  styles: [
    `
      .mat-dialog-roam-content {
        padding: 1rem 1.5rem;
      }

      .mat-dialog-roam-action {
        padding: 0.875rem 1.5rem;
        justify-content: space-between;
        background-color: #f5f5f5;
      }

      .btn-close {
        border: none;
        background: none;
        font-size: 14px;
        font-weight: 600;
      }
    `,
  ],
})
export class DialogComplianceViolationsCategoryComponent {
  public icon = iconLib;
  constructor(
    public dialogRef: MatDialogRef<DialogComplianceViolationsCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  submit(): void {}
}
