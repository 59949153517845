<app-container-dialog-set
  [isValid]="form.valid"
  (onClose)="close()"
  (onSave)="submit()">
  <form container [formGroup]="form">
    <div class="set-grid set-grid-2 pt-10">
      <app-roam-select
        formControlName="propertyId"
        placeholder="Associations"
        [items]="assocationOptions()" />
      <app-roam-select 
        formControlName="unitTypeId"
        placeholder="Unit types" 
        [items]="unitTypeOptions()" />
      <div class="pt-10">
        <app-roam-input formControlName="name" name="Unit Name" />
      </div>
      <div class="pt-10">
        <app-roam-input formControlName="slug" name="Slug" />
      </div>
      <div class="pt-10">
        <app-roam-input formControlName="restrooms" name="Bedrooms" />
      </div>
      <div class="pt-10">
        <app-roam-input formControlName="bathrooms" name="Bathrooms" />
      </div>
      <div class="pt-10">
        <app-roam-input formControlName="maxOccupancy" name="Max Occupants" />
      </div>
      <div class="pt-10">
        <app-roam-input formControlName="address" name="Post Office Address" />
      </div>
      <div class="pt-10">
        <app-roam-input formControlName="latitude" name="Latitude" />
      </div>
      <div class="pt-10">
        <app-roam-input formControlName="longitude" name="Longitude" />
      </div>
    </div>
    <div class="pt-20">
      <app-roam-text-area formControlName="notes" name="Notes" />
    </div>
    <div class="pt-20">
      <mat-checkbox formControlName="active">
        <span class="fw-600">This unit is active</span>
      </mat-checkbox>
    </div>
  </form>
</app-container-dialog-set>
