<div>
  <app-global-form-tabs [tabs]="tabs" [(tabSelected)]="tab" />

  <hr />

  <ng-container [ngSwitch]="tab">
    <app-global-form-association-compliance-violations-stages
      *ngSwitchCase="'violations stages'"
    />
    <app-global-form-association-compliance-pets *ngSwitchCase="'pets'" />
    <app-global-form-association-compliance-parking *ngSwitchCase="'parking'" />
    <app-global-form-association-compliance-inspections
      *ngSwitchCase="'inspections'"
    />
    <app-global-form-association-compliance-violations-category
      *ngSwitchCase="'violations category'"
    />
    <app-global-form-association-compliance-architectural-requests
      *ngSwitchCase="'architectural requests'"
    />
  </ng-container>
</div>
