import { SelectionModel } from "@angular/cdk/collections";
import { ConnectionPositionPair, OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import {
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  model,
  output,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { dropdownPosition } from "@app/core/const/overlay.const";
import { iconLib } from "@app/core/const/roam-icon";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { IUser } from "@app/shared/interfaces";
import { UserService } from "@app/shared/services/user.service";
import { debounceTime, distinctUntilChanged, finalize, startWith } from "rxjs";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OverlayModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    RoamButtonComponent,
    MatProgressSpinnerModule,
  ],
  selector: "app-search-users-dropdown-button",
  styleUrl: "./search-users-dropdown-button.cmp.scss",
  templateUrl: "./search-users-dropdown-button.cmp.html",
})
export class SearchUsersDropdownButtonCmp {
  #destroyRef = inject(DestroyRef);
  #api = inject(UserService);

  readonly icon = iconLib;
  readonly dropdownPosition: ConnectionPositionPair[] = dropdownPosition;

  readonly isLoading = signal(false);
  readonly opened = model(false);
  readonly buttonLabel = input("Add Users");
  protected selectionIds = new SelectionModel<string>(true);
  readonly selectionsSubmit = output<IUser[]>();
  readonly searchForm = new FormControl<string>("");
  readonly keyword = model<string>("");
  readonly users = signal<IUser[]>([]);
  readonly isEmpty = computed(() => !this.users().length);

  open = () => {
    this.opened.set(true);
  };

  close = () => {
    this.opened.set(false);
    this.selectionIds.clear();
    this.searchForm.setValue("");
  };

  toggle = () => {
    const opened = this.opened();
    if (opened) {
      this.close();
    } else {
      this.open();
    }
  };

  onSubmit(): void {
    const ids = this.selectionIds.selected;
    const selections = this.users().filter(x => ids.includes(x.id));
    this.selectionsSubmit.emit(selections);
    this.close();
  }

  searchUsers = (keyword: string) => {
    this.isLoading.set(true);
    this.#api
      .freeSearch(keyword)
      .pipe(
        finalize(() => this.isLoading.set(false)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        next: resp => {
          this.users.set(resp || []);
        },
      });
  };

  ngAfterViewInit(): void {
    this.searchForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(this.searchForm.value),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(term => {
        this.keyword.set(term!);
      });
  }

  protected onKeywordChange = effect(() => this.searchUsers(this.keyword()), {
    allowSignalWrites: true,
  });
}
