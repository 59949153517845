import { Component, Input, signal } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { iconLib, iconPropertyTypes } from "@app/core/const/roam-icon";
import { AssociationService } from "@app/shared/services/association.service";
import { Subject, Subscription, takeUntil } from "rxjs";

interface ICardIcon {
  id: string;
  title: string;
  slug: string;
  icon: string;
  focus: boolean;
}

@Component({
  selector: "app-global-form-association-general-property-types",
  templateUrl:
    "./global-form-association-general-property-types.component.html",
  styleUrls: [
    "./global-form-association-general-property-types.component.scss",
  ],
})
export class GlobalFormAssociationGeneralPropertyTypesComponent {
  @Input()
  public associationId?: string;

  @Input("associationPropertyTypes")
  public set setAssociationPropertyTypes(
    associationPropertyTypes: any | undefined,
  ) {
    console.log(associationPropertyTypes);
  }

  private destroy$ = new Subject<void>();
  public isLoading = signal<boolean>(false);
  public iconButton = iconLib;
  private icon = iconPropertyTypes;
  public propertyTypesList: ICardIcon[] = [
    {
      id: "1",
      title: "Single Family",
      slug: "single-family",
      icon: this.icon.singleFamily.src,
      focus: false,
    },
    {
      id: "2",
      title: "Multi Family",
      slug: "multi-family",
      icon: this.icon.multiFamily.src,
      focus: false,
    },
    {
      id: "3",
      title: "Condo",
      slug: "condo",
      icon: this.icon.condo.src,
      focus: false,
    },
    {
      id: "4",
      title: "Townhome",
      slug: "town-home",
      icon: this.icon.townhome.src,
      focus: false,
    },
    {
      id: "5",
      title: "Camper/RV",
      slug: "camper-rv",
      icon: this.icon.camper.src,
      focus: false,
    },
    {
      id: "6",
      title: "Other",
      slug: "other",
      icon: this.icon.other.src,
      focus: false,
    },
    {
      id: "7",
      title: "Barn",
      slug: "barn",
      icon: this.icon.barn.src,
      focus: false,
    },
    {
      id: "8",
      title: "Container",
      slug: "container",
      icon: this.icon.container.src,
      focus: false,
    },
  ];

  selectPropertyType(item: any): void {
    this.propertyTypesList.forEach((mc) => {
      if (item.id === mc.id) {
        mc.focus = !mc.focus;
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.associationId) {
      console.log("Association General Property Types Edit");
      // this.associationService
      //   .editAssociationsPropertyTypes(this.associationId,this.propertyTypesList)
      //   .pipe(takeUntil(this.destroy$))
      //   .subscribe();
    } else {
      console.log("Association General Property Types Add");
    }
  }

  constructor(
    private associationService: AssociationService,
    private dialogRef: MatDialogRef<GlobalFormAssociationGeneralPropertyTypesComponent>,
  ) {}

  ngOnInit() {
    if (this.associationId) {
      this.associationService
        .getListOfUnitTypes(this.associationId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((val) => {
          console.log(val);
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
