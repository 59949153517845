<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <div class="set-grid set-grid-2 pt-10">
      <app-roam-select
        formControlName="paymentMethodId"
        placeholder="Payment Method"
        [items]="paymentMethodOptions ?? []"
      />
      <app-roam-select
        formControlName="paymentTermsId"
        placeholder="Payment Terms"
        [items]="paymentTermOptions ?? []"
      />
      <app-roam-input name="Bank Name" />
      <app-roam-input name="Bank Addresss" />
      <app-roam-input name="Bank City,State Zip" />
      <app-roam-input name="Bank Code" />
      <app-roam-input name="Deposit Routing" />
      <app-roam-input name="Deposit Account" />
    </div>
  </ng-container>
</app-container-dialog-set>
