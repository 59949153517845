<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Document</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <h6 class="fw-500 font-14px tc-black mb-8">Attachments</h6>

    <app-attachment-upload></app-attachment-upload>
  </ng-container>
</app-container-dialog-set>
