<app-container-dialog-set (onClose)="close()" (onSave)="submit()">
  <ng-container container>
    <label class="fw-500">Violation Stages</label>
    <div class="roam-scrollbar mt-16">
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        @for(data of listData;track data;let data_i = $index){
        <div class="d-flex gap-16 f-column mb-16" cdkDrag>
          <div class="list-violation-stage">
            <div class="number-counter">{{ data_i + 1 }}</div>

            <div class="list-item-violation-stage">
              @if(data.edit){
              <app-roam-input name="Stage Name" />
              <app-roam-input name="Notification Template" />
              <div class="violation-detail-action">
                <app-roam-button label="Save" />
                <span
                  (click)="onEdit(data_i)"
                  class="text-btn-underline fw-500"
                >
                  Cancel
                </span>
              </div>
              }@else{
              <div class="fw-500">{{ data.stageName }}</div>
              <div class="fw-300">{{ data.notificationTemplate }}</div>
              }
            </div>
            <!-- action -->
            @if(!data.edit){
            <button
              aria-label="show card menu"
              [matMenuTriggerFor]="tableMenu"
              [matMenuTriggerData]="{ index: data_i }"
              class="more-button"
              mat-icon-button
              type="button"
            >
              <mat-icon>more_horiz</mat-icon>
            </button>
            }
          </div>
        </div>
        }
      </div>
    </div>
    <div class="mt-16 mb-16">
      <app-button-add
        [round]="true"
        [label]="'Add Stage'"
        (onAdd)="addStages()"
      ></app-button-add>
    </div>
  </ng-container>
</app-container-dialog-set>

<mat-menu class="board-card-menu" #tableMenu="matMenu">
  <ng-template matMenuContent let-index="index">
    <button mat-menu-item (click)="onEdit(index)">
      <div class="align-center gap-8 font-14 fw-500">
        <div class="d-flex" [inlineSVG]="'./assets/svg/edit-black.svg'"></div>
        <div>Edit</div>
      </div>
    </button>
    <button mat-menu-item (click)="onDelete(index)">
      <div class="delete-btn align-center gap-8 font-14 fw-500">
        <div
          class="d-flex"
          [inlineSVG]="'./assets/svg/trash-red-icon.svg'"
        ></div>
        <div>Delete</div>
      </div>
    </button>
  </ng-template>
</mat-menu>
