import { Component } from "@angular/core";
import { iconLib } from "../../../../../../core/const/roam-icon";

@Component({
  selector: "app-global-form-contact-form-contact",
  templateUrl: "./global-form-contact-form-contact.component.html",
})
export class GlobalFormContactFormContactComponent {
  public icon = iconLib;
  public phones = [1, 2];

  public submit(): void {}
}
