import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { iconLib } from "@app/core/const/roam-icon";
import {
  IAssociationEmail,
  IAssociationPhone,
} from "../../../../../../pages/associations/shared/interface/association-detail.interface";
import { Subject } from "rxjs";

@Component({
  selector: "app-global-form-association-general-contacts",
  templateUrl: "./global-form-association-general-contacts.component.html",
  styleUrls: ["./global-form-association-general-contacts.component.scss"],
})
export class GlobalFormAssociationGeneralContactsComponent {
  @Input()
  public associationId?: string;

  @Input("associationEmails")
  public set setAssociationEmails(
    associationEmails: IAssociationEmail[] | undefined,
  ) {
    if (associationEmails) this.patchFormEmails(associationEmails);
  }

  @Input("associationPhones")
  public set setAssociationPhones(
    associationPhones: IAssociationPhone[] | undefined,
  ) {
    if (associationPhones) this.patchFormPhones(associationPhones);
  }

  private destroy$ = new Subject<void>();
  public form: FormGroup;
  public icon = iconLib;

  get formPhones(): FormArray {
    return this.form.get("phones") as FormArray;
  }

  createFormPhone(): FormGroup {
    return this.formBuilder.group({
      name: [""],
      number: [""],
    });
  }

  patchFormPhones(phones: IAssociationPhone[]): void {
    this.formPhones.patchValue(phones);
  }

  addFormPhone(): void {
    this.formPhones.push(this.createFormPhone());
  }

  removePhone(index: number): void {
    this.formPhones.removeAt(index);
  }

  get formEmails(): FormArray {
    return this.form.get("emails") as FormArray;
  }

  createFormEmail(): FormGroup {
    return this.formBuilder.group({
      email: [""],
      name: [""],
    });
  }

  patchFormEmails(emails: IAssociationEmail[]): void {
    this.formEmails.patchValue(emails);
  }

  addFormEmail(): void {
    this.formEmails.push(this.createFormEmail());
  }

  removeEmail(index: number): void {
    this.formEmails.removeAt(index);
  }

  submit(): void {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association General Contact Edit");
    } else {
      console.log("Association General Contact Add");
    }

    console.log("DATA", formData);
  }

  close(): void {}

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      phones: this.formBuilder.array([this.createFormPhone()]),
      emails: this.formBuilder.array([this.createFormEmail()]),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
