import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-global-form-association-management-rates",
  templateUrl: "./global-form-association-management-rates.component.html",
})
export class GlobalFormAssociationManagementRatesComponent {
  @Input()
  public associationId?: string;

  @Input("associationManagement")
  public set setAssociationManagement(associationManagement: any | undefined) {
    if (associationManagement) this.patchForm(associationManagement);
  }

  public form: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.createForm();
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      contractExpires: [""],
      doorRate: [""],
      monthlyMinimum: [""],
    });
  }

  private patchForm(associationManagement: any) {
    this.form.patchValue({ ...associationManagement });
  }

  public submit() {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association Management Rates Edit");
    } else {
      console.log("Association  Management Rates Add");
    }

    console.log("DATA", formData);
  }
}
