<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="true"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container>
    <!-- section 1 -->
    <section>
      <label class="fw-500"
        >1. Create Resident Center and Communication Settings</label
      >
      <div class="pt-20 pb-20">
        <contact-select-architectural-dropdown />
      </div>

      <div>
        <div class="d-flex gap-16 f-column mb-16">
          @for(file of files; track file; let file_i = $index){
          <app-file-card-bar
            [name]="file.name"
            [date]="file.date"
            [user]="file.user"
            [size]="file.size"
            (onDelete)="deleteFile(file_i)"
          ></app-file-card-bar>
          }
        </div>

        <h6 class="title mb-8">Architectural Request Forms Attachments</h6>
        <div>
          <app-attachment-upload-button
            (onUploadedFiles)="uploadFile($event)"
          ></app-attachment-upload-button>
        </div>
      </div>

      <div class="pt-20 pb-20 set-grid set-grid-2">
        <app-roam-select placeholder="Default Approval Template" />
        <app-roam-select placeholder="Default Denial Template" />
        <app-roam-select placeholder="Sender" />
      </div>
    </section>

    <!-- section 2 -->

    <section>
      <label class="fw-500">2. Committee Setting</label>
      <div class="pt-20 pb-20">
        <app-roam-select placeholder="Select committee" />
      </div>
    </section>

    <!-- section 3 -->

    <section>
      <label class="fw-500"
        >3. Enable Architectural Requests in Resident Center</label
      >
      <div class="pt-20 pb-20">
        <app-roam-toggle-slider
          [label]="
            'Allow association to submit and view architectural requests and committee members to vote on architectural requests.'
          "
        ></app-roam-toggle-slider>
      </div>
    </section>
  </ng-container>
</app-container-dialog-set>
