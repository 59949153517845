import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../../../../services/api.service";

@Component({
  selector: "app-global-form-association-management-team",
  templateUrl: "./global-form-association-management-team.component.html",
})
export class GlobalFormAssociationManagementTeamComponent {
  @Input()
  public associationId?: string;

  @Input("associationManagerId")
  public set setAssociationManagerId(managerId: string | undefined) {
    if (managerId) this.patchForm(managerId);
  }

  public form: FormGroup;
  public managerOptions = [];

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.createForm();

    this.apiService.getManagers().subscribe((response) => {
      this.managerOptions = response.managers;
    });
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      managerId: [""],
    });
  }

  private patchForm(managerId: string) {
    this.form.patchValue({ managerId });
  }

  public submit() {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association Management Team Edit");
    } else {
      console.log("Association  Management Team Add");
    }

    console.log("DATA", formData);
  }
}
