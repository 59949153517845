import { Injectable, computed, signal } from "@angular/core";
import { IProperty } from "@app/shared/interfaces";

interface State {
  entities: IProperty[];
}

const initialState: State = {
  entities: [],
};

@Injectable({ providedIn: "root" })
export class PropertyStore {
  readonly #state = signal<State>(initialState);
  readonly state = this.#state.asReadonly();
  readonly propertiesList = computed(() => this.state().entities);

  resetState(): void {
    this.#state.set(initialState);
  }

  readonly addOne = (data: IProperty) => {
    return this.#state.update((state) => {
      return {
        ...state,
        entities: [...state.entities, data],
      };
    });
  };

  readonly removeOne = (data: IProperty) => {
    return this.#state.update((state) => {
      return {
        ...state,
        entities: [...state.entities.filter((x: IProperty) => x.id != data.id)],
      };
    });
  };
}
