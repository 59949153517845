import { Component, signal } from "@angular/core";

@Component({
  selector: "app-global-form-association-board-motions",
  templateUrl: "./global-form-association-board-motions.component.html",
  styleUrls: ["./global-form-association-board-motions.component.scss"],
})
export class GlobalFormAssociationBoardMotionsComponent {
  public isLoading = signal<boolean>(false);
  close(): void {}
  submit(): void {}
}
