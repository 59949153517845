<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Pet</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <div class="mb-24" *ngFor="let pet of [1, 2]; let pet_i = index">
      <app-roam-form-info
        [title]="'Pet Information'"
        [isDelete]="pet_i !== 0"
        [bg]="'dark'"
      >
        <ng-container content>
          <div class="form-section f-col-2">
            <app-roam-input [name]="'Pet Name'"></app-roam-input>
            <app-roam-input [name]="'Pet Type'"></app-roam-input>
            <app-roam-input [name]="'Breed'"></app-roam-input>
            <app-roam-input [name]="'Color'"></app-roam-input>
            <app-roam-input [name]="'Age'"></app-roam-input>
            <app-roam-input [name]="'Weight (lbs)'"></app-roam-input>
          </div>
        </ng-container>
      </app-roam-form-info>
    </div>

    <div class="mt-24">
      <app-button-add [label]="'Add Another'" [round]="true"></app-button-add>
    </div>
  </ng-container>
</app-container-dialog-set>
