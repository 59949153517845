import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { IUser, IUnit, IUnitTypeResponse } from "../interfaces";
import { IResponse } from "../interfaces/response.interface";
import { UserService } from "@app/shared/services/user.service";

@Injectable({
  providedIn: "root",
})
export class UnitService {
  private apiUrl = environment.apiUrl;

  private _selectedUnit$: BehaviorSubject<string[]> = new BehaviorSubject([""]);
  public selectedUnit$ = this._selectedUnit$.asObservable();

  private _selectClose$: BehaviorSubject<string> = new BehaviorSubject("");
  public selectClose$ = this._selectClose$.asObservable();

  private _units$: Subject<IUnit[]> = new Subject();
  public units$ = this._units$.asObservable();

  public managers: IUser[] = [];
  // private selectedManager: string = "";
  public filteredManagers: IUser[] = [];

  public unitName: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(
    private http: HttpClient,
    private userService: UserService
    // private indexedDbService: IndexedDbService,
  ) {}

  ngOnInit(): void {
    this.userService.getManagers().subscribe((data: IResponse<IUser[]>) => {
      this.managers = data["users"];
      this.filteredManagers = this.managers;
    });
  }

  // public async getAssociations(manager: string | undefined = "") {
  //   this.http
  //     .get<IResponse<IAssociation[]>>(`${this.apiUrl}/associations`, {
  //       withCredentials: true,
  //       headers: {
  //         manager,
  //       },
  //     })
  //     .pipe(
  //       tap((data) => {
  //         this._associations$.next(data["properties"]);
  //       }),
  //     )
  //     .subscribe();
  // }

  public getUnit = (id: string): Observable<IUnit> => {
    return this.http.get<any>(`${this.apiUrl}/units/${id}`, {
      withCredentials: true,
    });
  };

  public getUnitBySlug = (slug: string | null): Observable<any> => {
    return this.http.get<any>(`${this.apiUrl}/unit-slug/${slug}`, {
      withCredentials: true,
    });
  };

  // public getAssociationUnitsBySlug = (slug: string | null): Observable<any> => {
  //   return this.http.get<any>(`${this.apiUrl}/association-slug/${slug}/units`, { withCredentials: true });
  // }
  //

  public getUnitTypes(id: string): Observable<IUnitTypeResponse> {
    return this.http.get<IUnitTypeResponse>(
      `${this.apiUrl}/properties/${id}/unitTypes`,
      { withCredentials: true }
    );
  }

  addUnit = (data: any): Observable<any> => {
    return this.http.post<any>(`${this.apiUrl}/units`, data, {
      withCredentials: true,
    });
  };

  patchUnit = (id: string, data: any): Observable<any> => {
    return this.http.patch<any>(`${this.apiUrl}/units/${id}`, data, {
      withCredentials: true,
    });
  };

  public onSelectUnit(ids: string[]) {
    this._selectedUnit$.next(ids);
  }

  public onSelectClose() {
    this._selectClose$.next("");
  }
}
