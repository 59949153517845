import { Component, DestroyRef, inject, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputDropdownComponent } from "@app/shared/components/input/input-dropdown/input-dropdown.component";
import { ConnectionPositionPair, OverlayModule } from "@angular/cdk/overlay";
import { dropdownPosition } from "@app/core/const/overlay.const";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { InputSearchOneComponent } from "@app/shared/components/input/input-search-one/input-search-one.component";
import { MatIconModule } from "@angular/material/icon";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { iconLib } from "@app/core/const/roam-icon";
import { PropertyStore } from "../../data-access/property.store";
import { IProperty } from "@app/shared/interfaces";
import { VendorService } from "@app/shared/services/vendor.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ToastrService } from "ngx-toastr";
import { TOASTR_MESSAGE_CONTACT_PROPERTY } from "@app/shared/dialogs/global-form/const";
import { ROAM_TOASTR } from "@app/core/const/app-roam.const";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    InputDropdownComponent,
    OverlayModule,
    InputSearchOneComponent,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    RoamButtonComponent,
    MatProgressSpinnerModule,
  ],
  selector: "contact-select-property-dropdown",
  templateUrl: "./select-property-dropdown.component.html",
  styleUrls: ["./select-property-dropdown.component.scss"],
})
export class SelectPropertyDropdownComponent {
  store = inject(PropertyStore);
  tosService = inject(ToastrService);
  vendorService = inject(VendorService);
  #destroyRef = inject(DestroyRef);
  isListLoading = signal<boolean>(true);
  icon = iconLib;
  isDropdown!: boolean;
  dropdownPosition: ConnectionPositionPair[] = dropdownPosition;
  tosText = TOASTR_MESSAGE_CONTACT_PROPERTY;

  SELECTED_PROERTY = signal<IProperty[]>([]);
  LIST_PROPERTY = signal<IProperty[]>([]);

  searchProperty(keyword: string): void {
    this.isListLoading.set(true);
    this.vendorService
      .searchPropertyByName(keyword)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((val) => {
        this.isListLoading.set(false);
        this.LIST_PROPERTY.set(val.data);
      });
  }

  selectProperty(property: IProperty, e: any) {
    if (e.checked) {
      this.SELECTED_PROERTY.update((state) => {
        return [...state, property];
      });
    } else {
      this.SELECTED_PROERTY.update((state) => {
        return [...state.filter((x) => x.id != property.id)];
      });
    }
  }

  checkStatus(property: IProperty): boolean {
    const result = this.store
      .state()
      .entities.filter((val) => val.id == property.id);
    return result.length > 0;
  }

  save(): void {
    if (this.SELECTED_PROERTY().length > 0) {
      this.SELECTED_PROERTY().forEach((x) => {
        this.store.addOne(x);
      });
      this.tosService.success(this.tosText.SUCCESS_ADD, "", {
        ...ROAM_TOASTR.center,
      });
    }

    this.isDropdown = false;
    this.SELECTED_PROERTY.set([]);
    this.LIST_PROPERTY.set([]);
  }

  openDropdown(): void {
    this.isDropdown = !this.isDropdown;
    this.SELECTED_PROERTY.set([]);
    this.vendorService
      .getListProperties()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((val) => {
        this.isListLoading.set(false);
        this.LIST_PROPERTY.set(val["properties"]);
      });
  }

  closeDropdown(): void {
    this.isDropdown = false;
    this.SELECTED_PROERTY.set([]);
    this.LIST_PROPERTY.set([]);
  }

  constructor() {}
}
