import { DestroyRef, inject, Injectable, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ASYNC_STATE, TAsyncState } from "@app/pages/task/+data-access";
import { IUser } from "@app/shared/interfaces";
import { UserService } from "@app/shared/services/user.service";
import { RoamTableOptions } from "@app/utils";
import { finalize, timer } from "rxjs";

@Injectable()
export class UserStore {
  #destroyRef = inject(DestroyRef);
  #api = inject(UserService);

  readonly data = signal<IUser[]>([]);
  readonly total = signal(0);
  readonly asyncProgress = signal<TAsyncState>(ASYNC_STATE.IDLE);
  readonly params = signal<RoamTableOptions>({
    pageSize: 5,
    pageIndex: 1,
    sortActive: "",
    sortDirection: "asc",
  });

  setData = (users: IUser[]) => {
    this.data.set(users);
  };

  addOne(user: IUser): void {
    this.asyncProgress.set("submitting");
    // TODO: apply a real API call!
    timer(500)
      .pipe(
        finalize(() => this.asyncProgress.set("submitted")),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(() => {
        this.data.update(list => [user, ...list]);
      });
  }

  addMany(users: IUser[]): void {
    this.asyncProgress.set("submitting");
    // TODO: apply a real API call!
    timer(500)
      .pipe(
        finalize(() => this.asyncProgress.set("submitted")),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(() => {
        this.data.update(list => [...users, ...list]);
      });
  }

  removeOne(id: string): void {
    this.asyncProgress.set("submitting");
    // TODO: apply a real API call!
    timer(500)
      .pipe(
        finalize(() => this.asyncProgress.set("submitted")),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(() => {
        this.data.update(list => {
          return list.filter(item => item.id !== id);
        });
      });
  }

  getList(associationId: string, params: RoamTableOptions): void {
    const { pageIndex: page, pageSize: limit, ...others } = params;
    this.asyncProgress.set("loading");
    this.#api
      .searchUsers({ ...others, properties: [associationId], page, limit })
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: resp => {
          console.log(resp);
          this.data.set(resp.data || []);
          this.total.set(resp.meta?.total || 0);
          this.asyncProgress.set("loaded");
        },
        error: () => {
          this.asyncProgress.set("error");
        },
      });
  }
}
