import { Component, Input, OnDestroy, signal } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "@app/shared/services/api.service";
import { AssociationService } from "@app/shared/services/association.service";
import { IAssociationDetail } from "@app/pages/associations/shared/interface";
import { Subject, takeUntil } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { askingCancel } from "@app/pages/associations/shared/const/association-dialog.const";
import { ConfirmationDialogComponent } from "@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component";
import { dialogConfig } from "@app/core/const/dialog.const";
import { FormConfirmService } from "@app/shared/services/form-comfirm.service";
import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";
import { ToastrService } from "ngx-toastr";
import { ROAM_TOASTR } from "@app/core/const/app-roam.const";
import { TOASTR_MESSAGE_GENERAL_INFO } from "../../../const";

@Component({
  selector: "app-global-form-association-general-information",
  templateUrl: "./global-form-association-general-information.component.html",
})
export class GlobalFormAssociationGeneralInformationComponent
  implements OnDestroy
{
  @Input()
  public associationId?: string;

  @Input("associationDetail")
  public set setAssociationDetail(
    associationDetail: IAssociationDetail | undefined,
  ) {
    if (associationDetail) this.patchForm(associationDetail);
  }

  private destroy$ = new Subject<void>();
  private readonly toastrText = TOASTR_MESSAGE_GENERAL_INFO;
  public form: FormGroup;
  public options: any = {};
  public isLoading = signal<boolean>(false);
  public readonly yesNoOptions: any[] = [
    { code: "yes", id: true, name: "Yes" },
    { code: "no", id: false, name: "No" },
  ];

  openDialogConfirm(closeAll: boolean): void {
    const confirmationDialogText: IConfirmationDialog = askingCancel;

    this.dialog
      .open(ConfirmationDialogComponent, {
        ...dialogConfig.confirmDialog,
        data: {
          confirmation: confirmationDialogText,
        },
      })
      .afterClosed()
      .subscribe((val) => {
        if (val) {
          //save data and send to api
          this.submit();
          if (closeAll) this.dialogRef.close();

          this.formConfirmService.resetState();
        } else {
          let nextObjt = this.formConfirmService.formConfirmObjt.value;
          if (closeAll) {
            this.dialogRef.close();
          } else {
            nextObjt.moveToNextTab = true;
          }
          nextObjt.askFormSubmit = false;
          nextObjt.isFormDirty = false;
          this.formConfirmService.formConfirmObjt.next(nextObjt);
        }
      });
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", Validators.required],
      fullName: [""],
      slug: ["", Validators.required],
      destinationId: [""],
      currencyId: [""],
      languageId: [""],
      timezoneId: [""],
      latitude: [""],
      longitude: [""],
      address: [""],
      address2: [""],
      city: [""],
      state: [""],
      zip: [""],
      country: [""],
    });
  }

  patchForm(associationDetail: IAssociationDetail): void {
    this.form.patchValue({ ...associationDetail });
  }

  submit(): void {
    this.isLoading.set(true);
    const formData = this.collectDirtyValues(this.form);
    if (this.associationId) {
      this.associationService
        .editAssociationGeneralInfo(this.associationId, formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (val) => {
            this.isLoading.set(false);
            this.toastrService.success(
              this.toastrText.SUCCESS_EDIT_GENERAL_INFO,
              "",
              {
                ...ROAM_TOASTR.center,
              },
            );
          },
          error: () => {
            this.isLoading.set(false);
            this.toastrService.error(
              this.toastrText.FAILED_EDIT_GENERAL_INFO,
              "",
              {
                ...ROAM_TOASTR.center,
              },
            );
          },
        });
    } else {
      this.associationService
        .saveAssociationGeneralInfo(formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (val) => {
            this.isLoading.set(false);
            this.toastrService.success(
              this.toastrText.SUCCESS_ADD_GENERAL_INFO,
              "",
              {
                ...ROAM_TOASTR.center,
              },
            );
          },
          error: () => {
            this.isLoading.set(false);
            this.toastrService.error(
              this.toastrText.FAILED_ADD_GENERAL_INFO,
              "",
              {
                ...ROAM_TOASTR.center,
              },
            );
          },
        });
    }

    this.form.markAsPristine();
    this.formConfirmService.resetState();
  }

  collectDirtyValues(form: FormGroup): void {
    const dirtyValues: any = {};
    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        dirtyValues[key] = currentControl.value;
      }
    });
    return dirtyValues;
  }

  close(): void {
    if (this.form.dirty) {
      this.openDialogConfirm(true);
    } else {
      this.dialogRef.close();
    }
  }

  constructor(
    private apiService: ApiService,
    private associationService: AssociationService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<GlobalFormAssociationGeneralInformationComponent>,
    private formConfirmService: FormConfirmService,
    private toastrService: ToastrService,
  ) {
    this.isLoading.set(true);
    this.form = this.createForm();

    //get all options
    this.apiService
      .getLanguagesCurrenciesTimezones()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.isLoading.set(false);
        this.options = response;
      });

    //for checking if user change tab and need confirmation
    this.formConfirmService.formConfirmObjt
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val.askFormSubmit) {
          this.openDialogConfirm(false);
        }
      });

    //for checking if value form is dirty
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.form.dirty) {
        this.formConfirmService.formConfirmObjt.next({
          ...this.formConfirmService.formConfirmObjt.value,
          isFormDirty: true,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
