<ng-template #statusCellView let-row>
  @switch (row.statusName) {
    @case ("Denied") {
      <chip-ui size="sm" rounded="sm" status="danger">
        {{ row.statusName }}
      </chip-ui>
    }
    @case ("Active") {
      <chip-ui size="sm" rounded="sm" status="success">
        {{ row.statusName }}
      </chip-ui>
    }
    @case ("Invited") {
      <chip-ui size="sm" rounded="sm" status="warning">
        {{ row.statusName }}
      </chip-ui>
    }
    @default {
      <chip-ui size="sm" rounded="sm" status="basic">Unknown</chip-ui>
    }
  }
</ng-template>

<ng-template #defaultEmptyTemplate>
  <app-empty-state
    title="Nothing to display"
    subtitle="No customers available" />
</ng-template>

<div class="roam-mdc-progress-bar-wrapper">
  @if (isLoading()) {
    <mat-progress-bar mode="indeterminate" />
  }
</div>

<table
  matSort
  mat-table
  matSortDisableClear
  [dataSource]="dataSource()"
  [matSortActive]="opt.sortActive"
  [matSortDirection]="opt.sortDirection"
  (matSortChange)="onSortChange($event)">
  <ng-container [matColumnDef]="colDef.Index">
    <th
      *matHeaderCellDef
      class="cell-header-index"
      mat-header-cell
      mat-sort-header>
      ID
    </th>
    <td class="cell-index" mat-cell *matCellDef="let i = index">
      {{
        paginatorPageIndex === 0 ?
          i + 1
        : 1 + i + paginatorPageIndex * paginatorPageSize
      }}
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">
      <a (click)="viewRow.emit(row)" class="roam-text-link">
        {{ row.name || "Unknown" }}
      </a>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.UnitAddress">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Unit Address</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.address || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.AccountNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Account Number</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.accountNumber || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Generation">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Generation</th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.generation || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.TotalBalance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Account Balance</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.totalBalance || "N/A" }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.StatusName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let row">
      <p>
        <ng-template
          [ngTemplateOutlet]="statusCellView"
          [ngTemplateOutletContext]="{ $implicit: row }" />
      </p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.CreatedDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let row">
      <p class="fw-600">{{ row.createdAt | date: dateFmt }}</p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.RemoveRow">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="roam-text-nowrap">Remove</span>
    </th>
    <td mat-cell *matCellDef="let row">
      <button
        (click)="removeRow.emit(row[modelKey])"
        mat-icon-button
        matTooltip="Remove user"
        color="warn"
        type="button">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Portal">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Portal</th>
    <td mat-cell *matCellDef="let row">
      <p class="fw-600">
        @switch (row.statusName) {
          @case ("Denied") {
            <span class="roam-text-danger">
              {{ row.statusName }}
            </span>
          }
          @case ("Active") {
            <span class="roam-text-success">
              {{ row.statusName }}
            </span>
          }
          @case ("Invited") {
            <span class="roam-text-pruimary">
              {{ row.statusName }}
            </span>
          }
          @default {
            <span class="roam-text-basic">Unknown</span>
          }
        }
      </p>
      <p class="fw-600">
        <small>{{ row.createdAt | date: dateFmt }}</small>
      </p>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="colDef.Selections">
    <th class="cell-selection" mat-header-cell *matHeaderCellDef>
      <div class="content-center">
        <mat-checkbox
          class="hover-visible"
          [checked]="selections.isAllSelected"
          (change)="selections.toggleAll()" />
      </div>
    </th>
    <td class="cell-selection" mat-cell *matCellDef="let row">
      <div class="content-center">
        <mat-checkbox
          [checked]="selections.isSelected(row[modelKey])"
          (change)="selections.toggleOne(row[modelKey])"
          [class.visible]="selections.isSelected(row[modelKey])"
          class="hover-visible" />
      </div>
    </td>
  </ng-container>

  <tr mat-header-row class="table-header" *matHeaderRowDef="columnDefs()"></tr>

  <tr
    mat-row
    class="table-row"
    contentVisibleHoverUi
    childSelector=".hover-visible"
    [class.table-row-selected]="selections.isSelected(row[modelKey])"
    *matRowDef="let row; columns: columnDefs(); index as i"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="columnDefs().length">
      <ng-container *ngTemplateOutlet="emptyView() || defaultEmptyTemplate" />
    </td>
  </tr>
</table>

@if (!isEmpty() && showPagination()) {
  <mat-paginator
    [length]="total()"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageIndex]="opt.pageIndex - 1"
    [pageSize]="opt.pageSize"
    (page)="onPage($event)"
    aria-label="Select page of properties" />
}
