<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Document</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <div class="d-flex gap-10 c-pointer">
      <div
        class="chip"
        *ngFor="let chip of tabChips"
        [class.active]="selectedChip === chip"
        (click)="selectedChip = chip"
      >
        {{ chip }}
      </div>
    </div>

    <hr class="mb-20" />

    <ng-container [ngSwitch]="selectedChip">
      <ng-container *ngSwitchCase="'General'">
        <ng-container *ngTemplateOutlet="upload"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'Collection Letters'">
        <ng-container *ngTemplateOutlet="collectionLetter"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'Legal Letters'">
        <ng-container *ngTemplateOutlet="legalLetter"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</app-container-dialog-set>

<ng-template #upload>
  <h6 class="fw-500 font-14px tc-black mb-8">Attachments</h6>

  <app-attachment-upload></app-attachment-upload>
</ng-template>

<ng-template #collectionLetter>
  <app-global-form-contact-owner-document-letter />
</ng-template>

<ng-template #legalLetter>
  <app-global-form-contact-owner-document-letter />
</ng-template>
