<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Employee Info</h1>
  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="form.valid"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container [formGroup]="form">
    <div
      class="card-group"
      formArrayName="employees"
      *ngFor="let employee of formEmployees.controls; let form_i = index"
    >
      <ng-container [formGroupName]="form_i">
        <div class="employee-card-header">
          <label>
            {{ formEmployees.value[form_i].title }}
          </label>
          <button
            *ngIf="form_i !== 0"
            mat-icon-button
            class="trash-icon"
            (click)="removeEmployee(form_i)"
          >
            <span inlineSVG="/assets/svg/trash-red-icon.svg"></span>
          </button>
        </div>

        <div class="set-grid set-grid-2 mt-20">
          <app-roam-input formControlName="firstName" name="First Name" />

          <app-roam-input formControlName="lastName" name="Last Name" />

          <app-roam-input formControlName="groupName" name="Group Name" />

          <app-roam-input formControlName="jobTitle" name="Job Title" />

          <app-roam-input formControlName="phone" name="Phone" />

          <app-roam-input formControlName="email" name="Email" />
        </div>
      </ng-container>
    </div>

    <div class="mt-24">
      <button class="btn btn-o-primary" (click)="addEmployee()">
        <img [src]="icon.plusRed.src" [alt]="icon.plusRed.alt" />

        <span class="ml-8">Add Employee</span>
      </button>
    </div>
  </ng-container>
</app-container-dialog-set>
