<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="true"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container [formGroup]="form">
    <div class="card-group">
      <label class="fw-500">Inspection Categories</label>
      <div
        class="card-inspection"
        formArrayName="inspectionsCategory"
        *ngFor="let category of formInspections.controls; let formIndex = index"
      >
        <ng-container [formGroupName]="formIndex">
          <div>
            <app-roam-input
              formControlName="categoryName"
              name="Category Name"
            />
          </div>
        </ng-container>
        @if(formIndex !== 0){
        <button
          mat-icon-button
          class="trash-icon"
          (click)="removeCategory(formIndex)"
        >
          <span inlineSVG="/assets/svg/trash-red-icon.svg"></span>
        </button>
        }
      </div>
      <div class="mt-16">
        <label
          class="text-underline tc-primary c-pointer"
          (click)="addFormInspections()"
        >
          <img class="mr-8" [src]="icon.plusRed.src" [alt]="icon.plusRed.alt" />
          Add Category
        </label>
      </div>
    </div>
  </ng-container>
</app-container-dialog-set>
