import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AccountingService } from "@app/shared/services/accounting.service";

@Component({
  selector: "app-global-form-association-accounting-collections",
  templateUrl:
    "./global-form-association-accounting-collections.component.html",
})
export class GlobalFormAssociationAccountingCollectionsComponent {
  @Input("associationAccounting")
  public set setAssociationAccounting(associationAccounting: any | undefined) {
    if (associationAccounting) this.patchForm(associationAccounting);
  }

  @Input()
  public associationId?: string;

  @Input()
  public itemOptions?: any[];

  public form: FormGroup;

  constructor(
    private accountingService: AccountingService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.createForm();
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      attorneyItemId: [""],
      defaultAdminItemId: [""],
      interestItemId: [""],
      interestPercent: [""],
      lateFeeItemId: [""],
      lessGreater: [""],
      secondaryAdminItemId: [""],
      violationFineAmount: [""],
      violationFineItemId: [""],
      violationFinePercent: [""],
    });
  }

  private patchForm(associationAccounting: any) {
    this.form.patchValue({ ...associationAccounting });
  }

  public submit() {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      console.log("Association Accounting Collections Edit");

      this.accountingService
        .saveAssociationAccounting(this.associationId, formData)
        .subscribe();
    } else {
      console.log("Association Accounting Collections Add");
    }

    console.log("DATA", formData);
  }
}
