<app-container-dialog-set (onClose)="close()" (onSave)="submit()">
  <ng-container container>
    <label class="fw-500">Set your pet policy for this property.</label>

    <div class="set-grid set-grid-4 pt-10">
      @for(item of PetsCategory;track item.id){
      <app-shortcut-menu-card
        [shortcut]="item"
        (click)="selectPetsCategory(item)"
      >
      </app-shortcut-menu-card>
      }
    </div>

    @if(PetsCategory[0].focus){
    <div class="pet-detail">
      <label class="fw-500">Pet Details</label>
      <label class="fw-300"
        >Please select which pets are allowed in this roperty</label
      >
      <div class="pet-option">
        <mat-checkbox class="example-margin">Small Dogs</mat-checkbox>
        <mat-checkbox class="example-margin">Large Dogs</mat-checkbox>
        <mat-checkbox class="example-margin">Cats</mat-checkbox>
      </div>
      <app-roam-text-area
        [name]="
          'If you have any specific pet restrictions, please add them here..'
        "
      ></app-roam-text-area>
    </div>
    }
  </ng-container>
</app-container-dialog-set>
