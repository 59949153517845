import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { PropertyStore } from "../../data-access/property.store";
import { TableConfigModel } from "@app/shared/components/table/model";
import { IEmptyState } from "@app/shared/interfaces/empty-state-text.interface";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { TableComponent } from "@app/shared/components/table";
import {
  ContactVendorTablePropertyConst,
  ContactVendorTablePropertyLabelConst,
  ContactVendorTablePropertySortKeyConst,
} from "@app/pages/contacts/shared/const";
import { generateContactEmptyState } from "@app/pages/contacts/shared/const/contact-empty-state-text.const";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { InlineSVGModule } from "ng-inline-svg-2";
import { IProperty } from "@app/shared/interfaces";
import { ToastrService } from "ngx-toastr";
import { TOASTR_MESSAGE_CONTACT_PROPERTY } from "@app/shared/dialogs/global-form/const";
import { ROAM_TOASTR } from "@app/core/const/app-roam.const";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TableComponent,
    MatIconModule,
    MatButtonModule,
    InlineSVGModule,
  ],
  selector: "vendor-property-table",
  templateUrl: "./property-table.component.html",
  styleUrls: ["./property-table.component.scss"],
})
export class PropertyTableComponent {
  store = inject(PropertyStore);
  tosService = inject(ToastrService);
  isLoading = signal<boolean>(false);
  tableConfig!: TableConfigModel;
  emptyState!: IEmptyState;
  tosText = TOASTR_MESSAGE_CONTACT_PROPERTY;

  initTableConfig() {
    this.tableConfig = new TableConfigModel();
    this.tableConfig.dataKey = ContactVendorTablePropertyConst;
    this.tableConfig.dataLabel = ContactVendorTablePropertyLabelConst;
    this.tableConfig.dataSortKey = ContactVendorTablePropertySortKeyConst;
    this.tableConfig.generateDataType();
    this.tableConfig.dataType["units"] = "custom";
    this.tableConfig.dataType["city"] = "custom";
    this.tableConfig.dataType["action"] = "custom";
  }

  onMouseOverChange(data: any): void {
    // this.tableConfig.dataSource.forEach((each) => {
    //   each.isHover = each.id === data?.id;
    // });
  }

  onPageChange(event: PageEvent): void {
    // this.tableConfig.pageIndex = event.pageIndex;
    // this.tableConfig.pageSize = event.pageSize;
    // this.getData();
  }

  onSortChange(sort: Sort): void {
    // this.tableConfig.sortKey = sort.active;
    // this.tableConfig.sortOrder = sort.direction;
    // this.getData();
  }

  removeProperty(property: any): void {
    this.store.removeOne(property);
    this.tosService.success(this.tosText.SUCCESS_REMOVE, "", {
      ...ROAM_TOASTR.center,
    });
  }

  constructor() {
    effect(() => {
      this.tableConfig.dataSource = this.store.propertiesList();
      this.tableConfig.dataTotal = this.store.propertiesList().length;
    });
    this.initTableConfig();
    this.emptyState = generateContactEmptyState(false, "Property", "Property");
  }
}
