<div>
  <input-dropdown
    cdkOverlayOrigin
    #openVendor="cdkOverlayOrigin"
    placeholder="Select a Vendor Account"
    [floatingLabel]="false"
    [(ngModel)]="labelName"
    (click)="isDropdown = !isDropdown"
  />
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="openVendor"
  [cdkConnectedOverlayOpen]="isDropdown"
  cdkConnectedOverlayPush="true"
  [cdkConnectedOverlayWidth]="320"
  [cdkConnectedOverlayPositions]="dropdownPosition"
  [cdkConnectedOverlayViewportMargin]="7"
  (backdropClick)="isDropdown = false"
>
  <div class="dropdown pl-16 pr-16 pt-8 pb-0">
    <div class="pt-8 pb-16">
      <app-input-search-one (onSearch)="searchVendor($event)" />
    </div>

    @if(!createNewVendor){
    <div class="create-new-button" (click)="openCreateMenu()">
      <button mat-icon-button class="chip-button">
        <mat-icon fontSet="material-icons-outlined">add</mat-icon>
      </button>
      Create New
    </div>
    } @else{
    <div class="gutter form-create-new-vendor">
      <div class="header">
        <span>Create New</span>
        <mat-icon
          (click)="createNewVendor = false"
          class="icon-close"
          fontSet="material-icons-outlined"
          >close</mat-icon
        >
      </div>
      <div class="body" [formGroup]="form">
        <app-roam-input
          formControlName="vendorName"
          [name]="'Vendor Name'"
        ></app-roam-input>
        <app-roam-input formControlName="city" [name]="'City'"></app-roam-input>
        <app-roam-select formControlName="state" placeholder="Select a State" />
      </div>
      <div class="action">
        <app-roam-button
          [disabled]="!form.valid"
          label="Save"
          (onClick)="save()"
        />
      </div>
    </div>
    }

    <hr />

    <div class="gutter scroll-container">
      <div class="reverse hovered round-checkbox">
        @for (vendor of SAMPLE_VENDORS; track vendor.id) {
        <div class="list-vendors-button" (click)="selectVendor(vendor)">
          {{ vendor.name }}
        </div>
        }
      </div>
    </div>
  </div>
</ng-template>
