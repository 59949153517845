import {
  Component,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { CustomerAccountStore } from "@app/pages/customer/+data-access";
import { ColDefCustomerAccount } from "@app/pages/customer/+features/customer-accounts-table/customer-accounts-table.cmp";
import { IUser } from "@app/shared/interfaces";
import { dedupItems } from "@app/utils";
import { iconLib } from "../../../../../../core/const/roam-icon";

type FormType = FormGroup<{
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
  accountIds: FormControl<string[]>;
}>;

@Component({
  selector: "app-global-form-contact-form-personal",
  templateUrl: "./global-form-contact-form-personal.component.html",
  encapsulation: ViewEncapsulation.None,
  providers: [CustomerAccountStore],
  styles: [
    `
      app-global-form-contact-form-personal {
        .table-row {
          --roam-table-row-height: 4rem;
        }

        .table-heading {
          margin-bottom: 1rem;

          .title {
            font-weight: 500;
            font-size: 1.2rem;
            margin-bottom: 1rem;
          }
        }
      }
    `,
  ],
})
export class GlobalFormContactTenantPersonalComponent {
  public icon = iconLib;

  get fc() {
    return this.form().controls;
  }

  protected accountStore = inject(CustomerAccountStore);
  protected associationId: string | null = null;
  protected accountColDefs = signal<ColDefCustomerAccount[]>([
    "name",
    "accountNumber",
    "portal",
    "createdDate",
    "removeRow",
  ]);
  readonly detail = model<IUser | null>(null);
  readonly accounts = signal<any[]>([]);
  protected selectedIds = signal<string[]>([]);
  readonly form = input.required<FormType>();
  readonly formSubmit = output<void>();

  removeRow(id: string): void {
    this.accounts.update(list => {
      return list.filter(x => x.id !== id);
    });
  }

  addRows(customers: any[]): void {
    this.accounts.update(list => {
      return dedupItems([...list, ...customers], (a, b) => a.id === b.id);
    });
  }

  protected onDetailChange = effect(
    () => {
      const detail = this.detail();
      const customers = detail?.customers || [];
      this.accounts.set(customers);
    },
    { allowSignalWrites: true }
  );

  protected onAccountListChange = effect(
    () => {
      const ids = this.accounts().map(x => x.id);
      this.form().controls.accountIds.patchValue(ids);
    },
    { allowSignalWrites: true }
  );
}
