<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Marketing Profile</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <section>
      <h5 class="font-18px tc-primary fw-500">Profile</h5>
      <div class="set-grid set-grid-2 gap-24 mt-24">
        <app-roam-select [placeholder]="'Assigned To'"></app-roam-select>
        <app-roam-select [placeholder]="'Leas Status'"></app-roam-select>
        <app-roam-select [placeholder]="'Leas Source'"></app-roam-select>
        <app-roam-select [placeholder]="'Leas Medium'"></app-roam-select>
        <app-roam-input [name]="'Credit Score'"></app-roam-input>
        <app-roam-input [name]="'Monthly Income'"></app-roam-input>
      </div>
    </section>
    <hr class="mt-28" />
    <section>
      <h5 class="font-18px tc-primary fw-500">Search Criteria</h5>
      <div class="set-grid set-grid-2 gap-24 mt-24">
        <app-roam-input [name]="'Min Bedroom'"></app-roam-input>
        <app-roam-input [name]="'Min Bathunit'"></app-roam-input>
        <app-roam-input [name]="'Max Rent'"></app-roam-input>
        <app-roam-input [name]="'Desired Move-in'"></app-roam-input>
      </div>
    </section>
    <hr class="mt-28" />
    <section>
      <h5 class="font-18px tc-primary fw-500">Related To</h5>
      <div class="set-grid set-grid-2 gap-24 mt-24">
        <app-roam-select [placeholder]="'Property'"></app-roam-select>
      </div>
      <div class="mt-24">
        <app-button-add
          [label]="'Add Another Property'"
          [round]="true"
        ></app-button-add>
      </div>
    </section>
  </ng-container>
</app-container-dialog-set>
