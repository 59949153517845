<div>
  <app-roam-select [placeholder]="'Select lettername'"></app-roam-select>
  <div class="mt-18">
    <mat-checkbox>
      <div class="fw-500">Create Statement?</div>
    </mat-checkbox>
  </div>
  <div class="set-grid set-grid-2 gap-24 mt-20">
    <app-roam-datepicker [name]="'Letter date from'"></app-roam-datepicker>
    <app-roam-datepicker [name]="'Letter date to'"></app-roam-datepicker>
  </div>
  <div class="mt-20">
    <quill-editor
      class="roam-editor"
      [placeholder]="''"
      [modules]="editorConfig"
    >
    </quill-editor>
  </div>
</div>
