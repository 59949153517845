<ng-container [ngSwitch]="menu">
  <app-global-form-contact-form-address *ngSwitchCase="'address'" />

  <app-global-form-contact-form-contact *ngSwitchCase="'contact'" />

  <app-global-form-contact-form-dependent *ngSwitchCase="'dependent'" />

  <app-global-form-contact-owner-document *ngSwitchCase="'document'" />

  <app-global-form-contact-form-emergency *ngSwitchCase="'emergency'" />

  <app-global-form-contact-form-marketing *ngSwitchCase="'marketing'" />

  <app-global-form-contact-form-payment *ngSwitchCase="'payment'" />

  <app-global-form-contact-form-personal 
    *ngSwitchCase="'personal'"
    [form]="controls.personal"
    [detail]="contactDetail"
    (formSubmit)="onPersonalSubmit()"
  />

  <app-global-form-contact-form-pet *ngSwitchCase="'pet'" />

  <app-global-form-contact-form-vehicle *ngSwitchCase="'vehicle'" />
</ng-container>
