import { Component, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ROAM_TOASTR } from "@app/core/const/app-roam.const";
import { dialogConfig } from "@app/core/const/dialog.const";
import { iconLib } from "@app/core/const/roam-icon";
import { configmDelete } from "@app/pages/associations/shared/const/association-dialog.const";
import { ConfirmationDialogComponent } from "@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component";
import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";
import { ToastrService } from "ngx-toastr";
import { DialogComplianceViolationsCategoryComponent } from "./ui/dialog-category.component";
import { DialogComplianceViolationsTypeComponent } from "./ui/dialog-type.component";
import { TOASTR_MESSAGE_COMPLIANCE_VIOLATIONS_CATEGORY } from "../../../const";

interface ViolationCategoryI {
  categoryName: string;
  ListType: TypeI[];
}
interface TypeI {
  violationName: string;
  pinaltyFee: string;
  fineAmount: string;
  triggersAbatement: boolean;
  repeatContinously: boolean;
  markLeasing: boolean;
  automaticOnDriveThru: boolean;
  markInsurance: boolean;
  letterBody: string;
}

const MOCK_DATA: ViolationCategoryI[] = [
  {
    categoryName: "category 1",
    ListType: [
      {
        violationName: "abatement",
        pinaltyFee: "fine fee",
        fineAmount: "10000",
        triggersAbatement: true,
        repeatContinously: true,
        markLeasing: true,
        automaticOnDriveThru: true,
        markInsurance: true,
        letterBody: "data letter",
      },
      {
        violationName: "abatement",
        pinaltyFee: "fine fee",
        fineAmount: "10000",
        triggersAbatement: true,
        repeatContinously: true,
        markLeasing: true,
        automaticOnDriveThru: true,
        markInsurance: true,
        letterBody: "data letter",
      },
    ],
  },
  {
    categoryName: "category 2",
    ListType: [
      {
        violationName: "abatement",
        pinaltyFee: "fine fee",
        fineAmount: "10000",
        triggersAbatement: true,
        repeatContinously: true,
        markLeasing: true,
        automaticOnDriveThru: true,
        markInsurance: true,
        letterBody: "data letter",
      },
      {
        violationName: "abatement",
        pinaltyFee: "fine fee",
        fineAmount: "10000",
        triggersAbatement: true,
        repeatContinously: true,
        markLeasing: true,
        automaticOnDriveThru: true,
        markInsurance: true,
        letterBody: "data letter",
      },
    ],
  },
  {
    categoryName: "category 3",
    ListType: [
      {
        violationName: "abatement",
        pinaltyFee: "fine fee",
        fineAmount: "10000",
        triggersAbatement: true,
        repeatContinously: true,
        markLeasing: true,
        automaticOnDriveThru: true,
        markInsurance: true,
        letterBody: "data letter",
      },
      {
        violationName: "abatement",
        pinaltyFee: "fine fee",
        fineAmount: "10000",
        triggersAbatement: true,
        repeatContinously: true,
        markLeasing: true,
        automaticOnDriveThru: true,
        markInsurance: true,
        letterBody: "data letter",
      },
    ],
  },
  {
    categoryName: "category 4",
    ListType: [
      {
        violationName: "abatement",
        pinaltyFee: "fine fee",
        fineAmount: "10000",
        triggersAbatement: true,
        repeatContinously: true,
        markLeasing: true,
        automaticOnDriveThru: true,
        markInsurance: true,
        letterBody: "data letter",
      },
      {
        violationName: "abatement",
        pinaltyFee: "fine fee",
        fineAmount: "10000",
        triggersAbatement: true,
        repeatContinously: true,
        markLeasing: true,
        automaticOnDriveThru: true,
        markInsurance: true,
        letterBody: "data letter",
      },
    ],
  },
  {
    categoryName: "category 5",
    ListType: [
      {
        violationName: "abatement",
        pinaltyFee: "fine fee",
        fineAmount: "10000",
        triggersAbatement: true,
        repeatContinously: true,
        markLeasing: true,
        automaticOnDriveThru: true,
        markInsurance: true,
        letterBody: "data letter",
      },
      {
        violationName: "abatement",
        pinaltyFee: "fine fee",
        fineAmount: "10000",
        triggersAbatement: true,
        repeatContinously: true,
        markLeasing: true,
        automaticOnDriveThru: true,
        markInsurance: true,
        letterBody: "data letter",
      },
    ],
  },
];

@Component({
  selector: "app-global-form-association-compliance-violations-category",
  templateUrl:
    "./global-form-association-compliance-violations-category.component.html",
  styleUrls: [
    "./global-form-association-compliance-violations-category.component.scss",
  ],
})
export class GlobalFormAssociationComplianceViolationsCategoryComponent {
  public isLoading = signal<boolean>(false);
  public iconButton = iconLib;
  public listCategory = MOCK_DATA;
  private TOS_TEXT = TOASTR_MESSAGE_COMPLIANCE_VIOLATIONS_CATEGORY;

  addType(index: number): void {
    this.dialog
      .open(DialogComplianceViolationsTypeComponent, {
        ...dialogConfig.addDialogViolation,
        data: {},
      })
      .afterClosed()
      .subscribe(() => {
        this.toastrService.success(this.TOS_TEXT.SUCCESS_ADD_TYPE, "", {
          ...ROAM_TOASTR.center,
        });
      });
  }

  editType(index: number): void {}

  deleteType(index: number): void {
    const confirmationDialogText: IConfirmationDialog = configmDelete;

    this.dialog
      .open(ConfirmationDialogComponent, {
        ...dialogConfig.confirm,
        data: {
          confirmation: confirmationDialogText,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.toastrService.success(this.TOS_TEXT.SUCCESS_DELETE_TYPE, "", {
          ...ROAM_TOASTR.center,
        });
      });
  }

  addCategory(): void {
    this.dialog
      .open(DialogComplianceViolationsCategoryComponent, {
        ...dialogConfig.confirm,
        data: {},
      })
      .afterClosed()
      .subscribe(() => {
        this.toastrService.success(this.TOS_TEXT.SUCCESS_ADD_CATEGORY, "", {
          ...ROAM_TOASTR.center,
        });
      });
  }

  submit(): void {}

  close(): void {}

  constructor(
    private dialog: MatDialog,
    private toastrService: ToastrService,
  ) {}
}
