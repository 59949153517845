import { Component } from "@angular/core";
import { EditorConfig } from "@app/core/const/editor.const";
import { QuillModules } from "ngx-quill";

@Component({
  selector: "app-global-form-contact-owner-document-letter",
  templateUrl: "./global-form-contact-owner-document-letter.component.html",
})
export class GlobalFormContactOwnerDocumentLetterComponent {
  public editorConfig: QuillModules = EditorConfig;
}
