<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Contact Info</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <div class="tenant-phone-form">
      <app-roam-form-info [title]="'Phone'" [bg]="'dark'">
        <ng-container content>
          <div
            class="align-center mb-16"
            *ngFor="let phone of phones; let phone_i = index"
          >
            <div class="form-section f-col-2 flex-1 mt-0">
              <app-roam-select [placeholder]="'Type'"></app-roam-select>
              <app-roam-input [name]="'Phone'"></app-roam-input>
            </div>
            <div *ngIf="phones.length > 1">
              <span
                [style.visibility]="phone_i !== 0 ? 'visible' : 'hidden'"
                class="d-flex"
                [inlineSVG]="icon.binRed.src"
              ></span>
            </div>
          </div>
          <div>
            <app-button-add-underline></app-button-add-underline>
          </div>
        </ng-container>
      </app-roam-form-info>
    </div>

    <div class="tenant-email-form mt-24">
      <app-roam-form-info [title]="'Email'" [bg]="'dark'">
        <ng-container content>
          <div
            class="align-center mb-16"
            *ngFor="let email of [0, 1]; let email_i = index"
          >
            <div class="form-section f-col-2 flex-1 mt-0">
              <app-roam-select [placeholder]="'Type'"></app-roam-select>
              <app-roam-input [name]="'Phone'"></app-roam-input>
            </div>
            <div [style.visibility]="email_i !== 0 ? 'visible' : 'hidden'">
              <span class="d-flex" [inlineSVG]="icon.binRed.src"></span>
            </div>
          </div>
          <div>
            <app-button-add-underline></app-button-add-underline>
          </div>
        </ng-container>
      </app-roam-form-info>
    </div>
  </ng-container>
</app-container-dialog-set>
