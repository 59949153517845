<div class="justify-between align-center mb-30">
  <h1 class="font-24px">{{ menu | titlecase }}</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">
    Save & Exit
  </button>
</div>

<ng-container [ngSwitch]="menu">
  <app-global-form-association-general
    *ngSwitchCase="'general'"
    [associationDetail]="associationDetail"
    [associationAmenities]="associationAmenities"
    [tab]="tab"
  />

  <app-global-form-association-compliance
    *ngSwitchCase="'compliance'"
    [associationDetail]="associationDetail"
    [tab]="tab"
  />

  <app-global-form-association-accounting
    *ngSwitchCase="'accounting'"
    [associationAccounting]="associationAccounting"
    [associationDetail]="associationDetail"
    [tab]="tab"
  />

  <app-global-form-association-management
    *ngSwitchCase="'management'"
    [associationDetail]="associationDetail"
    [associationManagement]="associationManagement"
    [tab]="tab"
  />

  <app-global-form-association-board *ngSwitchCase="'board'" />

  <app-global-form-association-committees *ngSwitchCase="'committees'" />

  <app-global-form-association-meetings *ngSwitchCase="'meetings'" />

  <app-global-form-association-leasing *ngSwitchCase="'leasing'" />

  <app-global-form-association-portal *ngSwitchCase="'portal'" />

  <app-global-form-association-printing *ngSwitchCase="'printing'" />
</ng-container>
