<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Federal Taxes</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <mat-radio-group
      aria-label="Select an option"
      class="d-flex f-column"
      [(ngModel)]="sendVendor"
    >
      <mat-radio-button
        *ngFor="let option of federalTaxOptions; let option_i = index"
        [value]="option.value"
      >
        <div class="fw-500 c-pointer">{{ option.text }}</div>
      </mat-radio-button>
    </mat-radio-group>

    <ng-container *ngIf="sendVendor">
      <div class="set-grid set-grid-2 mt-24">
        <app-roam-input [name]="'Taxpayer Name'"></app-roam-input>
        <app-roam-select [placeholder]="'Taxpayer ID Type'"></app-roam-select>
        <app-roam-input [name]="'Taxpayer ID'"></app-roam-input>
      </div>

      <div class="mt-24">
        <app-roam-toggle-slider
          [label]="'Same as vendor\'s address'"
          [labelClass]="'fw-400'"
          [(ngModel)]="sameVendorAddr"
        ></app-roam-toggle-slider>
      </div>

      <div class="set-grid set-grid-2 mt-24" *ngIf="!sameVendorAddr">
        <app-roam-input [name]="'Street 1'"></app-roam-input>
        <app-roam-input [name]="'Street 2'"></app-roam-input>
        <app-roam-input [name]="'City'"></app-roam-input>
        <app-roam-input [name]="'State'"></app-roam-input>
        <app-roam-select [placeholder]="'Country'"></app-roam-select>
        <app-roam-select [placeholder]="'Zip Code'"></app-roam-select>
      </div>
    </ng-container>
  </ng-container>
</app-container-dialog-set>
