<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <a class="btn btn-primary" (click)="addUnitType()">Add Unit Type</a>

    <div class="flex-container unitTypeDiv" [formGroup]="form">
      <div class="input-column">
        <div *ngFor="let thisUnitType of associationUnitTypes">
          <a class="btn btn-primary" (click)="removeUnitType()"
            >Delete Unit Type</a
          >
          <div class="row">
            <div class="col-md-8">
              <app-roam-select
                class="text-input"
                [items]="unitTypes"
                formControlName="unitType"
                placeholder="Unit Type"
              ></app-roam-select>
            </div>
          </div>

          <br />

          <div class="row">
            <app-roam-input
              class="inputs"
              formControlName="assessmentDayOfMonth"
              name="Day of Month"
            ></app-roam-input>
          </div>

          <br />

          <div class="row">
            <app-roam-input
              class="inputs"
              formControlName="lateFeeAmount"
              name="Late Fee $"
            ></app-roam-input>
          </div>

          <br />

          <div class="row">
            <app-roam-input
              class="inputs"
              formControlName="lateFeePercent"
              name="Late Fee %"
            ></app-roam-input>
          </div>

          <br />

          <div class="row">
            <app-roam-input
              class="inputs"
              formControlName="interestAmount"
              name="Interest $"
            ></app-roam-input>
          </div>

          <br />

          <div class="row">
            <app-roam-input
              class="inputs"
              formControlName="interestPercent"
              name="Interest %"
            ></app-roam-input>
          </div>

          <br />

          <div class="row">
            <app-roam-input
              class="inputs"
              formControlName="initiationFee"
              name="Initiation Fee"
            ></app-roam-input>
          </div>

          <br />

          <div class="row">
            <app-roam-input
              class="inputs"
              formControlName="resaleFee"
              name="Resale Fee"
            ></app-roam-input>
          </div>

          <br />

          <div class="row">
            <app-roam-input
              class="inputs"
              formControlName="transferFee"
              name="Transfer Fee"
            ></app-roam-input>
          </div>
        </div>
      </div>
      <div class="checkbox-column">
        <div class="row">
          <app-roam-input
            class="inputs"
            formControlName="assessmentAmount"
            name="Total Yearly Assessment"
          ></app-roam-input>
        </div>

        <div class="row" *ngFor="let month of months; let i = index">
          <mat-checkbox>{{ month.name }}</mat-checkbox>
          <app-roam-input
            class="assessment"
            formControlName="assessmentAmount"
            name="Assessment Amount"
          ></app-roam-input>
          <br />
        </div>
      </div>
    </div>
  </ng-container>
</app-container-dialog-set>
