import { CurrencyPipe, DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  output,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ChipUi } from "@app/pages/customer/+ui";
import { ContentVisibleHoverUi } from "@app/pages/job/+ui";
import { ButtonActionComponent } from "@app/shared/components/button/button-action/button-action.component";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import {
  ROAM_TABLE_BASE_MODULES,
  ROAM_TABLE_COL_DEF_BASE,
  RoamTableAccessor,
} from "@app/utils";
import { ExtractEnum } from "@app/utils/typing.utils";
import { CustomerAccount } from "../../+data-access";

export const COL_DEF_CUSTOMER_ACCOUNT = {
  ...ROAM_TABLE_COL_DEF_BASE,
  Name: "name",
  UnitAddress: "unitAddress",
  AccountNumber: "accountNumber",
  Generation: "generation",
  TotalBalance: "totalBalance",
  CreatedDate: "createdDate",
  StatusName: "statusName",
  Portal: "portal",
  RemoveRow: "removeRow",
} as const;

export type ColDefCustomerAccount = ExtractEnum<
  typeof COL_DEF_CUSTOMER_ACCOUNT
>;

@Component({
  standalone: true,
  selector: "app-customer-accounts-table-alt",
  styleUrl: "./customer-accounts-table.cmp.scss",
  templateUrl: "customer-accounts-table.cmp.html",
  host: { class: "roam-mdc-table-wrapper" },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    ...ROAM_TABLE_BASE_MODULES,
    RoamCardComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    ButtonActionComponent,
    ContentVisibleHoverUi,
    ChipUi,
    DatePipe,
    CurrencyPipe,
  ],
})
export class CustomerAccountsTableCmp extends RoamTableAccessor<
  CustomerAccount,
  ColDefCustomerAccount
> {
  protected dateFmt = "dd/MM/yyyy";
  readonly removeRow = output<typeof this.modelKey>();
  readonly viewRow = output<CustomerAccount>();

  protected get opt() {
    return this.options();
  }

  protected get colDef() {
    return COL_DEF_CUSTOMER_ACCOUNT;
  }

  constructor() {
    super({ modelKey: "id" });
  }
}
