import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AccountingService } from "@app/shared/services/accounting.service";

@Component({
  selector: "app-global-form-association-accounting-assessments",
  templateUrl:
    "./global-form-association-accounting-assessments.component.html",
  styleUrls: [
    "./global-form-association-accounting-assessments.component.scss",
  ],
})
export class GlobalFormAssociationAccountingAssessmentsComponent
  implements OnInit
{
  public associationId: string = "";
  public associationUnitTypes = [{ id: "xyz", unitTypeId: "abc" }];
  public unitTypes = [
    { id: "abc", name: "Single Family" },
    { id: "123", name: "Townhouse" },
    { id: "456", name: "Office" },
  ];
  public months = [
    { name: "January", id: 1 },
    { name: "February", id: 2 },
    { name: "March", id: 3 },
    { name: "April", id: 4 },
    { name: "May", id: 5 },
    { name: "June", id: 6 },
    { name: "July", id: 7 },
    { name: "August", id: 8 },
    { name: "September", id: 9 },
    { name: "October", id: 10 },
    { name: "November", id: 11 },
    { name: "December", id: 12 },
  ];

  public form: FormGroup = this.formBuilder.group({
    unitType: [""],
    assessmentDayOfMonth: [""],
    assessmentAmount: [""],
    initiationFee: [""],
    resaleFee: [""],
    transferFee: [""],
    lateFeeAmount: [""],
    lateFeePercent: [""],
    interestAmount: [""],
    interestPercent: [""],
    monthAmounts: this.formBuilder.array([]),
  });

  constructor(
    private formBuilder: FormBuilder,
    public accountingService: AccountingService,
  ) {}

  ngOnInit(): void {
    this.form.patchValue({
      unitType: "",
      assessmentDayOfMonth: "",
      assessmentAmount: "",
      initiationFee: "",
      resaleFee: "",
      transferFee: "",
      lateFeeAmount: "",
      lateFeePercent: "",
      interestAmount: "",
      interestPercent: "",
    });
  }

  public addUnitType(): void {
    console.log("add unit type");
  }

  public removeUnitType(): void {
    console.log("remove unit type");
  }

  public submit() {
    console.log("assessments save");
    const formData = this.form.value;
    console.log("Form data", formData);

    const directData = {};

    this.accountingService
      .saveAssociationAccounting(this.associationId, directData)
      .subscribe((response: any) => {
        console.log("Direct data saved", response);
      });
  }
}
