<app-container-dialog-set
  [isValid]="true"
  [isLoading]="isLoading()"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container>
    <label class="fw-500"
      >Which types best describe the units on this property?</label
    >

    <div class="set-grid set-grid-4 pt-10">
      <app-shortcut-menu-card
        *ngFor="let type of propertyTypesList"
        [shortcut]="type"
        (click)="selectPropertyType(type)"
      >
      </app-shortcut-menu-card>
    </div>
  </ng-container>
</app-container-dialog-set>
