import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CustomerReqBody, CustomerReqResp } from "./models/customer-req";
import { environment } from "@environments/environment";
import { CustomerAccount } from "./models/customer";

@Injectable({ providedIn: "root" })
export class CustomerApiService {
  readonly #http = inject(HttpClient);

  getAccounts(opts: CustomerReqBody.GetAccounts = { limit: 10, page: 1 }) {
    return this.#http.post<CustomerReqResp.GetAccounts>(
      `${environment.apiUrl}/customer-accounts/search`,
      opts,
      { withCredentials: true }
    );
  }

  addAccount(body: CustomerReqBody.AddAccount) {
    return this.#http.post<CustomerReqResp.GetAccounts>(
      `${environment.apiUrl}/customer-accounts`,
      body,
      { withCredentials: true }
    );
  }

  patchAccount(id: string, body: CustomerReqBody.PatchAccount) {
    return this.#http.patch<CustomerReqResp.GetAccounts>(
      `${environment.apiUrl}/customer-accounts/${id}`,
      body,
      { withCredentials: true }
    );
  }

  getCustomerAccounts(params: CustomerReqBody.GetAccounts) {
    return this.#http.post<CustomerReqResp.GetAccounts>(
      `${environment.apiUrl}/customer-accounts/search`,
      params,
      {
        withCredentials: true,
      }
    );
  }

  freeSearch(keyword: string) {
    return this.#http.get<CustomerAccount[]>(
      `${environment.apiUrl}/customer-accounts/keyword-search/${keyword}`,
      {
        withCredentials: true,
      }
    );
  }
}
