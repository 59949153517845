import { Component, DestroyRef, inject, Input, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NonNullableFormBuilder } from "@angular/forms";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { RoamToastrService } from "@app/pages/task/+data-access";
import { UserService } from "@app/shared/services/user.service";
import { filter } from "rxjs";

@Component({
  selector: "app-global-form-contact-owner",
  templateUrl: "./global-form-contact-owner.component.html",
})
export class GlobalFormContactOwnerComponent {
  #destroyRef = inject(DestroyRef);
  #config = inject(UserConfigStore);
  #api = inject(UserService);
  protected readonly toastr = inject(RoamToastrService);
  protected readonly fb = inject(NonNullableFormBuilder);
  readonly isSubmitting = signal(false);
  readonly associationId = signal("");

  @Input()
  public contactDetail?: any;

  @Input()
  public menu!: string;

  @Input()
  public tab?: string;

  readonly form = this.fb.group({
    personal: this.fb.group({
      firstName: this.fb.control<string>(""),
      lastName: this.fb.control<string>(""),
      email: this.fb.control<string>(""),
      phone: this.fb.control<string>(""),
      accountIds: this.fb.control<string[]>([]),
    }),
  });

  get controls() {
    return this.form.controls;
  }

  onPersonalSubmit(): void {
    if (this.contactDetail?.id) {
      this.patchCustomerAccount(this.contactDetail.id);
    } else {
      this.addCustomerAccount();
    }
  }

  addCustomerAccount(): void {
    const { firstName, lastName, accountIds, ...values } =
      this.controls.personal.getRawValue();
    this.isSubmitting.set(true);
    this.#api
      .addUser({
        id: crypto.randomUUID(),
        ...values,
        name: firstName,
        customers: accountIds.map(id => ({ id })),
        roleSlug: "owner",
      })
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.toastr.success("Customer account created successfully!");
          this.isSubmitting.set(false);
        },
        error: () => {
          this.toastr.danger("Failed to create customer account!");
          this.isSubmitting.set(false);
        },
      });
  }

  patchCustomerAccount(id: string): void {
    const { firstName, lastName, accountIds, ...values } =
      this.controls.personal.getRawValue();
    this.isSubmitting.set(true);
    this.#api
      .patchUser(id, {
        ...values,
        name: firstName,
        customers: accountIds.map(id => ({ id })),
        roleSlug: "owner",
      })
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.toastr.success("Customer account updated successfully!");
          this.isSubmitting.set(false);
        },
        error: () => {
          this.toastr.danger("Failed to update customer account!");
          this.isSubmitting.set(false);
        },
      });
  }

  ngOnInit(): void {
    const prop = this.contactDetail;
    if (prop) {
      this.controls.personal.patchValue({
        firstName: prop.name,
        lastName: prop.name, // TODO: there would be no firstName + lastName, instead will use `name`
        email: prop.contactEmail,
        phone: prop.contactPhone,
      });
    }
  }

  constructor() {
    this.#config.selectedAssociationId$
      .pipe(filter(Boolean), takeUntilDestroyed())
      .subscribe(id => {
        this.associationId.set(id);
      });
  }
}
