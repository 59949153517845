<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <div class="set-grid set-grid-2 pt-10">
      <app-roam-select
        formControlName="managerId"
        placeholder="Manager"
        [items]="managerOptions"
      />
    </div>
  </ng-container>
</app-container-dialog-set>
