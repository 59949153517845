<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <label class="fw-500">Internal Documents Uploaded</label>
    <hr />
    <div>
      <div class="d-flex gap-16 f-column mb-16">
        @for(file of files;let file_i = $index;track file_i ){
        <app-file-card-bar
          [name]="file.name"
          [date]="file.date"
          [user]="file.user"
          [size]="file.size"
          (onDelete)="deleteFile(file_i)"
        ></app-file-card-bar>
        }
      </div>

      <h6 class="title mb-8">Attachments</h6>
      <div>
        <app-attachment-upload-button
          (onUploadedFiles)="uploadFile($event)"
        ></app-attachment-upload-button>
      </div>
    </div>
  </ng-container>
</app-container-dialog-set>
